
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { checkSubscriptionStatus } from '../redux/textsSlice';
import styled from 'styled-components';

const StatusContainer = styled.div`
  margin-bottom: 20px;
`;

const SubscriptionStatus = () => {
  const dispatch = useDispatch();
  const status = useSelector((state) => state.texts.subscriptionStatus);
  const currentPeriodEnd = useSelector((state) => state.texts.currentPeriodEnd);
  const error = useSelector((state) => state.texts.subscriptionError);

  useEffect(() => {
    dispatch(checkSubscriptionStatus());
  }, [dispatch]);

  return (
    <StatusContainer>
      <h3>Subscription Status: {status}</h3>
      {currentPeriodEnd && <p>Subscription ends on: {new Date(currentPeriodEnd).toLocaleDateString()}</p>}
      {error && <div style={{ color: 'red' }}>{error}</div>}
    </StatusContainer>
  );
};

export default SubscriptionStatus;
