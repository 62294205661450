import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Modal,
  Box,
  Typography,
  Button,
  CircularProgress,
} from '@mui/material';

const VerifyEmail = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false); // Состояние для открытия/закрытия модального окна
  const [modalMessage, setModalMessage] = useState(''); // Сообщение в модальном окне
  const [isLoading, setIsLoading] = useState(true); // Состояние загрузки

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        await axios.get(`https://e-lingvo.duckdns.org/api/verify-email/${token}/`);
        setIsLoading(false); // Загрузка завершена
        navigate('/login'); // Перенаправление на страницу входа
      } catch (error) {
        setIsLoading(false); // Загрузка завершена
        setModalMessage('Email verification failed. Please try again.'); // Устанавливаем сообщение об ошибке
        setOpenModal(true); // Открываем модальное окно
      }
    };

    verifyEmail();
  }, [token, navigate]);

  // Закрытие модального окна и перенаправление на страницу регистрации
  const handleCloseModal = () => {
    setOpenModal(false);
    navigate('/register');
  };

  return (
    <div>
      <h2>Verifying your email...</h2>
      {isLoading && <CircularProgress />} {/* Индикатор загрузки */}

      {/* Модальное окно */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
            Verification Failed
          </Typography>
          <Typography id="modal-description" sx={{ mb: 2 }}>
            {modalMessage}
          </Typography>
          <Button
            variant="contained"
            onClick={handleCloseModal}
            sx={{ mt: 2 }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default VerifyEmail;