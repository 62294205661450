// import axios from 'axios';

// // Создаем экземпляр axios с базовым URL
// const api = axios.create({
//   baseURL: 'https://e-lingvo.duckdns.org/',
// });

// // Перехватчик для добавления токена в заголовки запросов
// api.interceptors.request.use(
//   (config) => {
//     const accessToken = localStorage.getItem('access_token');
//     if (accessToken) {
//       config.headers['Authorization'] = `Bearer ${accessToken}`;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// // Перехватчик для обработки ошибок и обновления токена
// api.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     const originalRequest = error.config;

//     // Проверяем, что ошибка связана с истекшим токеном и запрос еще не повторялся
//     if (error.response?.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true; // Помечаем запрос как повторный

//       const refreshToken = localStorage.getItem('refresh_token');
//       if (refreshToken) {
//         try {
//           // Запрашиваем новый access token
//           const response = await axios.post(`${api.defaults.baseURL}/token/refresh/`, {
//             refresh: refreshToken,
//           });

//           const { access } = response.data;

//           // Сохраняем новый access token в localStorage
//           localStorage.setItem('access_token', access);

//           // Обновляем заголовок Authorization в оригинальном запросе
//           originalRequest.headers['Authorization'] = `Bearer ${access}`;

//           // Повторяем оригинальный запрос с новым токеном
//           return api(originalRequest);
//         } catch (refreshError) {
//           // Если обновление токена не удалось, очищаем localStorage и перенаправляем на страницу логина
//           localStorage.removeItem('access_token');
//           localStorage.removeItem('refresh_token');
//           window.location.href = '/login';
//           return Promise.reject(refreshError);
//         }
//       } else {
//         // Если refresh token отсутствует, перенаправляем на страницу логина
//         localStorage.removeItem('access_token');
//         localStorage.removeItem('refresh_token');
//         window.location.href = '/login';
//         return Promise.reject(error);
//       }
//     }

//     // Возвращаем ошибку, если она не связана с истекшим токеном
//     return Promise.reject(error);
//   }
// );

// export default api;


import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';

// Создаем экземпляр axios с базовым URL
const api = axios.create({
  baseURL: 'https://e-lingvo.duckdns.org/api',
});

// Перехватчик для добавления токена в заголовки запросов
api.interceptors.request.use(
  async (config) => {
    const accessToken = await AsyncStorage.getItem('access_token');
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


// Перехватчик для обработки ошибок и обновления токена
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Проверяем, что ошибка связана с истекшим токеном и запрос еще не повторялся
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true; // Помечаем запрос как повторный

      const refreshToken = await AsyncStorage.getItem('refresh_token');
      if (refreshToken) {
        try {
          // Запрашиваем новый access token
          const response = await axios.post(`${api.defaults.baseURL}/token/refresh/`, {
            refresh: refreshToken,
          });

          const { access } = response.data;

          // Сохраняем новый access token в AsyncStorage
          await AsyncStorage.setItem('access_token', access);

          // Обновляем заголовок Authorization в оригинальном запросе
          originalRequest.headers['Authorization'] = `Bearer ${access}`;

          // Повторяем оригинальный запрос с новым токеном
          return api(originalRequest);
        } catch (refreshError) {
          // Если обновление токена не удалось, очищаем AsyncStorage и перенаправляем на страницу логина
          await AsyncStorage.removeItem('access_token');
          await AsyncStorage.removeItem('refresh_token');
          window.location.href = '/login';
          return Promise.reject(refreshError);
        }
      } else {
        // Если refresh token отсутствует, перенаправляем на страницу логина
        await AsyncStorage.removeItem('access_token');
        await AsyncStorage.removeItem('refresh_token');
        window.location.href = '/login';
        return Promise.reject(error);
      }
    }

    // Возвращаем ошибку, если она не связана с истекшим токеном
    return Promise.reject(error);
  }
);

export default api;