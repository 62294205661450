// import React, { useState } from 'react';
// import { useDispatch } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import { requestPasswordReset } from '../redux/textsSlice';
// import { Form, Button, Alert } from 'react-bootstrap';

// const PasswordResetRequest = () => {
//   const [email, setEmail] = useState('');
//   const [error, setError] = useState(null);
//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       await dispatch(requestPasswordReset({ email })).unwrap();
//       alert('Password reset email sent. Please check your inbox.');
//       navigate('/login');
//     } catch (err) {
//       setError('Password reset request failed. Please try again.');
//     }
//   };

//   return (
//     <div className="container mt-5">
//       <h2>Request Password Reset</h2>
//       {error && <Alert variant="danger">{error}</Alert>}
//       <Form onSubmit={handleSubmit}>
//         <Form.Group controlId="formEmail">
//           <Form.Label>Email</Form.Label>
//           <Form.Control
//             type="email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             placeholder="Enter email"
//           />
//         </Form.Group>
//         <Button variant="primary" type="submit" className="mt-3">
//           Request Reset
//         </Button>
//       </Form>
//     </div>
//   );
// };

// export default PasswordResetRequest;


import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { requestPasswordReset } from '../redux/textsSlice';
import {
  TextField,
  Button,
  IconButton,
  Alert,
  Container,
  Typography,
  Box,
  CircularProgress,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';


const PasswordResetRequest = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // Состояние загрузки
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null); // Сброс ошибки перед каждой попыткой

    setIsLoading(true); // Включаем загрузку

    try {
      await dispatch(requestPasswordReset({ email })).unwrap();
      alert('Password reset email sent. Please check your inbox.');
      navigate('/login');
    } catch (err) {
      setError('Password reset request failed. Please try again.');
    } finally {
      setIsLoading(false); // Выключаем загрузку
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 5 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBack />
        </IconButton>
        <Typography variant="h4" gutterBottom align="center">
           Request Password Reset
        </Typography>
      </Box>

      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ mb: 2 }}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          disabled={isLoading} // Блокируем кнопку при загрузке
          sx={{ mt: 3, mb: 2 }}
        >
          {isLoading ? <CircularProgress size={24} /> : 'Request Reset'} {/* Индикатор загрузки */}
        </Button>
      </Box>
    </Container>
  );
};

export default PasswordResetRequest;