import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGroupedWords, createWord, updateWord, deleteWord } from '../redux/textsSlice';
import { Button, TextField, Alert, MenuItem, Select, FormControl, InputLabel, Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem, ListItemText, Collapse, IconButton, Paper, Box, Typography, Badge, ButtonGroup } from '@mui/material';
import { VolumeUp, Edit, Delete, Search, ArrowBack, PlayArrow, Stop, Lightbulb, ExpandMore, ExpandLess } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const FullDictionary = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const groupedWords = useSelector((state) => state.texts.groupedWords);
  const user = useSelector((state) => state.auth.user);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [newWord, setNewWord] = useState('');
  const [newTranslation, setNewTranslation] = useState('');
  const [message, setMessage] = useState(null);
  const [openGroups, setOpenGroups] = useState({});

  // Состояния для тренировок
  const [showListeningModal, setShowListeningModal] = useState(false);
  const [currentListeningWordIndex, setCurrentListeningWordIndex] = useState(0);
  const [selectedTranslation, setSelectedTranslation] = useState(null);

  // Состояния для редактирования перевода
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedWord, setSelectedWord] = useState(null);

  // Состояния для поиска
  const [searchQuery, setSearchQuery] = useState('');

  // Состояния для группировки
  const [groupingField, setGroupingField] = useState('first_letter');
  const [selectedGroup, setSelectedGroup] = useState(null);

  const themeMode = useSelector((state) => state.theme.theme); // Получаем текущую тему

  const groupingOptions = [
    { value: 'first_letter', label: 'By first letter' },
    { value: 'part_of_speech', label: 'By part of speech' },
  ];

  useEffect(() => {
    dispatch(fetchGroupedWords());
  }, [dispatch]);

  const handlePlayAudio = (text, lang = user.text_language, voice = user.text_voice) => {
    let lang_tr = transformLang(lang);
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ type: 'speak', text: text, lang_tr, comp: ' ', voice: voice })
      );
    } else {
      const msg = new SpeechSynthesisUtterance(text);
      msg.lang = lang_tr;
      window.speechSynthesis.speak(msg);
    }
  };

  const transformLang = (lang) => {
    const langMap = {
      'de-DE': 'de-DE',
      'en-US': 'en-US',
      'ru-RU': 'ru-RU',
    };
    return langMap[lang] || 'en-US';
  };

  const handleCreateWord = () => {
    dispatch(createWord({ text: newWord, translation: newTranslation })).then(() => {
      setNewWord('');
      setNewTranslation('');
      setShowCreateModal(false);
      dispatch(fetchGroupedWords());
    });
  };

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const handleDeleteWord = (wordId) => {
    dispatch(deleteWord(wordId)).then(() => {
      dispatch(fetchGroupedWords());
    });
  };

  const filteredWords = Object.values(groupedWords)
    .flat()
    .filter((word) => {
      if (!word || !word.text || !word.translation) {
        return false; // Пропускаем некорректные элементы
      }
      const query = searchQuery.toLowerCase();
      return (
        word.text.toLowerCase().includes(query) ||
        word.translation.toLowerCase().includes(query)
      );
    });

  const groupedByField = filteredWords.reduce((acc, word) => {
    if (!word) return acc; // Пропускаем некорректные элементы
    let key;
    if (groupingField === 'first_letter') {
      key = word.text[0].toUpperCase();
    } else {
      key = word[groupingField] || 'Other';
    }
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(word);
    return acc;
  }, {});

  const handleGroupClick = (group) => {
    setOpenGroups((prevOpenGroups) => ({
      ...prevOpenGroups,
      [group]: !prevOpenGroups[group],
    }));
    setSelectedGroup(group); // Устанавливаем выбранную группу
  };

  const handleListeningModeToggle = () => {
    if (!selectedGroup || !groupedByField[selectedGroup]) {
      setMessage({ text: 'Please select a group first!', variant: 'warning' });
      return;
    }
    setShowListeningModal(!showListeningModal);
    setCurrentListeningWordIndex(0);
    setSelectedTranslation(null);
  };

  const handleTranslationSelect = (translation) => {
    setSelectedTranslation(translation);
    const currentWord = groupedByField[selectedGroup][currentListeningWordIndex];
    if (translation === currentWord.translation) {
      setMessage({ text: 'Correct!', variant: 'success' });
      if (currentListeningWordIndex < groupedByField[selectedGroup].length - 1) {
        setCurrentListeningWordIndex(currentListeningWordIndex + 1);
        setSelectedTranslation(null);
      } else {
        setMessage({ text: 'You have completed the listening exercise!', variant: 'success' });
        setShowListeningModal(false);
      }
    } else {
      setMessage({ text: 'Incorrect!', variant: 'danger' });
    }
  };

  const getRandomTranslations = (word) => {
    if (!word || !word.id) {
      console.error("Invalid word object:", word);
      return [];
    }

    const translations = Object.values(groupedByField)
      .flat()
      .filter((w) => w && w.id && w.id !== word.id) // Проверяем, что w и w.id существуют
      .map((w) => w.translation);

    const randomTranslations = shuffleArray(translations).slice(0, 3);
    return shuffleArray([...randomTranslations, word.translation]);
  };

  const handleFlashcards = () => {
    if (!selectedGroup || !groupedByField[selectedGroup]) {
      setMessage({ text: 'Please select a group first!', variant: 'warning' });
      return;
    }
    const flashcards = groupedByField[selectedGroup].map((word) => ({
      text: word.text,
      translation: word.translation,
    }));
    navigate('/flashcards', { state: { sentences: flashcards } });
  };

  const handleSuggestiveTraining = () => {
    if (!selectedGroup || !groupedByField[selectedGroup]) {
      setMessage({ text: 'Please select a group first!', variant: 'warning' });
      return;
    }
    const trainingData = groupedByField[selectedGroup].map((word) => ({
      text: word.text,
      translation: word.translation,
    }));
    navigate('/suggestive-training', { state: { sentences: trainingData } });
  };

  const handleEditTranslation = (word) => {
    setSelectedWord(word);
    setShowEditModal(true);
  };

  const handleSaveTranslation = () => {
    if (selectedWord) {
      dispatch(updateWord({
        wordId: selectedWord.id,
        text: selectedWord.text,
        translation: selectedWord.translation,
      })).then(() => {
        setShowEditModal(false);
        setSelectedWord(null);
        dispatch(fetchGroupedWords());
      });
    }
  };

  return (
    <Box sx={{ padding: 0, backgroundColor: "var(--background-color)", color: "var(--text-color)" }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <IconButton onClick={() => navigate(-1)} sx={{ color: "var(--primary-color)" }}>
          <ArrowBack />
        </IconButton>
        <Typography variant="h5" sx={{ ml: 2, color: "var(--primary-color)" }}>
          Full Dictionary
        </Typography>
      </Box>

      {message && (
        <Alert severity={message.variant} onClose={() => setMessage(null)}>
          {message.text}
        </Alert>
      )}

      <Box sx={{ display: 'flex', mb: 3, mt: 2 }}>
        <TextField
          type="text"
          placeholder="Search by word or translation..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{ mr: 2, flexGrow: 1, backgroundColor: "var(--background-color)", color: "var(--text-color)" }}
        />
        <Button variant="outlined" startIcon={<Search />} sx={{ color: "var(--primary-color)", borderColor: "var(--primary-color)" }}>
          Search
        </Button>
      </Box>

      <Box sx={{ display: 'flex', mb: 3 }}>
        <FormControl variant="outlined" sx={{ mr: 2, minWidth: 120 }}>
          <InputLabel>Group by</InputLabel>
          <Select
            value={groupingField}
            onChange={(e) => setGroupingField(e.target.value)}
            label="Group by"
            sx={{ backgroundColor: "var(--background-color)", color: "var(--text-color)" }}
          >
            {groupingOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
        <ButtonGroup>
          <IconButton onClick={handleListeningModeToggle} sx={{ color: "var(--primary-color)" }}>
            <VolumeUp />
          </IconButton>
          <IconButton onClick={handleFlashcards} sx={{ color: "var(--primary-color)" }}>
            <Lightbulb />
          </IconButton>
          <IconButton onClick={handleSuggestiveTraining} sx={{ color: "var(--primary-color)" }}>
            <PlayArrow />
          </IconButton>
        </ButtonGroup>
      </Box>

      <Button variant="contained" color="success" onClick={() => setShowCreateModal(true)} sx={{ mt: 2, backgroundColor: "var(--primary-color)" }}>
        Add New Word
      </Button>

      <List sx={{ mt: 3 }}>
        {Object.entries(groupedByField).map(([group, words]) => (
          <Paper
            key={group}
            sx={{
              mb: 1,
              p: 2,
              backgroundColor: "var(--background-color)",
              color: "var(--text-color)",
              border: "1px solid var(--border-color)",
            }}
          >
            <ListItem
              button
              onClick={() => handleGroupClick(group)}
              sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Typography variant="body1">{group}</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Badge badgeContent={words.length} color="primary" sx={{ mr: 2, color: "var(--primary-color)" }} />
                {openGroups[group] ? <ExpandLess /> : <ExpandMore />}
              </Box>
            </ListItem>
            <Collapse in={openGroups[group]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {words.map((word) => (
                  <ListItem key={word.id} sx={{ pl: 4 }}>
                    <ListItemText primary={`${word.text} - ${word.translation}`} />
                    <ButtonGroup>
                      <IconButton onClick={() => handlePlayAudio(word.text)} size="small" sx={{ padding: '4px', color: "var(--primary-color)" }}>
                        <VolumeUp fontSize="small" />
                      </IconButton>
                      <IconButton onClick={() => handleEditTranslation(word)} size="small" sx={{ padding: '4px', color: "var(--primary-color)" }}>
                        <Edit fontSize="small" />
                      </IconButton>
                      <IconButton onClick={() => handleDeleteWord(word.id)} size="small" sx={{ padding: '4px', color: "var(--primary-color)" }}>
                        <Delete fontSize="small" />
                      </IconButton>
                    </ButtonGroup>
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </Paper>
        ))}
      </List>

      <Dialog open={showCreateModal} onClose={() => setShowCreateModal(false)}>
        <DialogTitle>Create New Word</DialogTitle>
        <DialogContent>
          <TextField
            label="Word"
            value={newWord}
            onChange={(e) => setNewWord(e.target.value)}
            fullWidth
            margin="normal"
            sx={{ backgroundColor: "var(--background-color)", color: "var(--text-color)" }}
          />
          <TextField
            label="Translation"
            value={newTranslation}
            onChange={(e) => setNewTranslation(e.target.value)}
            fullWidth
            margin="normal"
            sx={{ backgroundColor: "var(--background-color)", color: "var(--text-color)" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowCreateModal(false)}>Close</Button>
          <Button onClick={handleCreateWord} color="primary">Create</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showEditModal} onClose={() => setShowEditModal(false)}>
        <DialogTitle>Edit Translation</DialogTitle>
        <DialogContent>
          {selectedWord && (
            <TextField
              label="Word"
              value={selectedWord.text}
              disabled
              fullWidth
              margin="normal"
              sx={{ backgroundColor: "var(--background-color)", color: "var(--text-color)" }}
            />
          )}
          {selectedWord && (
            <TextField
              label="Translation"
              value={selectedWord.translation}
              onChange={(e) =>
                setSelectedWord({ ...selectedWord, translation: e.target.value })
              }
              fullWidth
              margin="normal"
              sx={{ backgroundColor: "var(--background-color)", color: "var(--text-color)" }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowEditModal(false)}>Close</Button>
          <Button onClick={handleSaveTranslation} color="primary">Save Changes</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showListeningModal} onClose={() => setShowListeningModal(false)}>
        <DialogTitle>Listening Mode</DialogTitle>
        <DialogContent>
          {selectedGroup && groupedByField[selectedGroup]?.length > 0 && (
            <Box>
              {/* Кнопка для воспроизведения текущего слова */}
              <Box sx={{ textAlign: 'center', mb: 3 }}>
                <Button
                  onClick={() => handlePlayAudio(groupedByField[selectedGroup][currentListeningWordIndex].text)}
                  variant="outlined"
                  sx={{
                    color: "var(--primary-color)",
                    borderColor: "var(--primary-color)",
                    '&:hover': {
                      backgroundColor: "var(--primary-color)",
                      color: "var(--background-color)",
                    },
                  }}
                >
                  <VolumeUp sx={{ mr: 1 }} /> Play Current Word
                </Button>
              </Box>

              {/* Плитки с вариантами переводов */}
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
                  gap: 2,
                }}
              >
                {getRandomTranslations(groupedByField[selectedGroup][currentListeningWordIndex]).map((translation, index) => (
                  <Box
                    key={index}
                    onClick={() => handleTranslationSelect(translation)}
                    sx={{
                      p: 2,
                      textAlign: 'center',
                      backgroundColor:
                        selectedTranslation === translation
                          ? translation === groupedByField[selectedGroup][currentListeningWordIndex].translation
                            ? 'var(--primary-color)' // Правильный ответ
                            : '#ff4444' // Неправильный ответ
                          : 'var(--background-color)',
                      color:
                        selectedTranslation === translation
                          ? 'var(--background-color)' // Цвет текста при выборе
                          : 'var(--text-color)',
                      border: '1px solid var(--border-color)',
                      borderRadius: 1,
                      cursor: 'pointer',
                      transition: 'background-color 0.3s ease, color 0.3s ease',
                      '&:hover': {
                        backgroundColor: selectedTranslation ? undefined : 'rgba(255, 140, 66, 0.1)', // Подсветка при наведении
                      },
                    }}
                  >
                    <Typography variant="body1">{translation}</Typography>
                  </Box>
                ))}
              </Box>

              {/* Сообщение о результате */}
              {message && (
                <Box sx={{ mt: 2, textAlign: 'center' }}>
                  <Alert severity={message.variant} onClose={() => setMessage(null)}>
                    {message.text}
                  </Alert>
                </Box>
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowListeningModal(false)}
            sx={{ color: "var(--primary-color)" }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default FullDictionary;