import { createTheme } from '@mui/material/styles';

// Основные цвета
const primaryColor = '#ff8c42'; // Оранжевый
const darkPrimaryColor = '#e67339'; // Темный оранжевый

// Светлая тема
export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: primaryColor,
    },
    background: {
      default: '#ffffff', // Светлый фон
      paper: '#ffffff', // Фон для карточек и модальных окон
    },
    text: {
      primary: '#000000', // Темный текст
      secondary: '#666666', // Вторичный текст
    },
    border: {
      main: 'rgba(0, 0, 0, 0.1)', // Граница для светлой темы
    },
  },
  typography: {
    fontFamily: 'Arial, sans-serif', // Шрифт по умолчанию
  },
});

// Темная тема
export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: primaryColor,
    },
    background: {
      default: '#121212', // Темный фон
      paper: '#1e1e1e', // Фон для карточек и модальных окон
    },
    text: {
      primary: '#e0e0e0', // Светлый текст
      secondary: '#b0b0b0', // Вторичный текст
    },
    border: {
      main: 'rgba(255, 255, 255, 0.1)', // Граница для темной темы
    },
  },
  typography: {
    fontFamily: 'Arial, sans-serif', // Шрифт по умолчанию
  },
});