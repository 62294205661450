// // themeSlice.js
// import { createSlice } from '@reduxjs/toolkit';

// const themeSlice = createSlice({
//   name: 'theme',
//   initialState: {
//     theme: 'light', // По умолчанию светлая тема
//     color: '#00ced1', // Бирюзовый цвет по умолчанию
//   },
//   reducers: {
//     toggleTheme: (state) => {
//       state.theme = state.theme === 'light' ? 'dark' : 'light';
//     },
//     setThemeColor: (state, action) => {
//       state.color = action.payload; // Устанавливаем выбранный цвет
//     },
//   },
// });

// export const { toggleTheme, setThemeColor } = themeSlice.actions;
// export default themeSlice.reducer;


import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AsyncStorage from '@react-native-async-storage/async-storage';

// Асинхронное действие для загрузки настроек темы из AsyncStorage
export const loadThemeSettings = createAsyncThunk(
  'theme/loadThemeSettings',
  async () => {
    const theme = await AsyncStorage.getItem('theme');
    const color = await AsyncStorage.getItem('themeColor');
    return {
      theme: theme || 'light', // По умолчанию светлая тема
      color: color || '#00ced1', // Бирюзовый цвет по умолчанию
    };
  }
);

// Асинхронное действие для сохранения настроек темы в AsyncStorage
export const saveThemeSettings = createAsyncThunk(
  'theme/saveThemeSettings',
  async ({ theme, color }) => {
    await AsyncStorage.setItem('theme', theme);
    await AsyncStorage.setItem('themeColor', color);
  }
);

const themeSlice = createSlice({
  name: 'theme',
  initialState: {
    theme: 'light', // По умолчанию светлая тема
    color: '#00ced1', // Бирюзовый цвет по умолчанию
    loading: false,
    error: null,
  },
  reducers: {
    toggleTheme: (state) => {
      state.theme = state.theme === 'light' ? 'dark' : 'light';
    },
    setThemeColor: (state, action) => {
      state.color = action.payload; // Устанавливаем выбранный цвет
    },
  },
  extraReducers: (builder) => {
    builder
      // Обработка загрузки настроек
      .addCase(loadThemeSettings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loadThemeSettings.fulfilled, (state, action) => {
        state.loading = false;
        state.theme = action.payload.theme;
        state.color = action.payload.color;
      })
      .addCase(loadThemeSettings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Обработка сохранения настроек
      .addCase(saveThemeSettings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveThemeSettings.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(saveThemeSettings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { toggleTheme, setThemeColor } = themeSlice.actions;
export default themeSlice.reducer;