import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { analyzeText, setArticleText } from "../redux/textsSlice";
import { TextField, Button, Select, MenuItem, FormControl, InputLabel, CircularProgress, Alert, Container, Typography, InputAdornment } from '@mui/material';
import { useNavigate } from "react-router-dom";
import ReactQuill from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Title, Description, Translate, Subject } from '@mui/icons-material'; // Иконки для полей

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'color': [] }, { 'background': [] }],
    ['link', 'image'],
    ['clean'],
    [{ 'size': ['small', false, 'large', 'huge'] }],
  ],
};

const formats = [
  'bold', 'italic', 'underline', 'strike',
  'color', 'background',
  'font',
  'link', 'image',
  'size',
];

const AddTextPage = () => {
  const [text, setText] = useState("");
  const [title, setTitle] = useState("");
  const [theme, setTheme] = useState("");
  const [articleLanguage, setArticleLanguage] = useState("de-DE");
  const [translationLanguage, setTranslationLanguage] = useState("ru-RU");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { themes, articleText } = useSelector((state) => state.texts);
  const user = useSelector((state) => state.auth.user);

  const quillRef = useRef(null);

  useEffect(() => {
    if (articleText) {
      setText(articleText);
    }
  }, [articleText]);

  const handleChange = (value) => {
    setText(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!text || !title || !theme || !articleLanguage || !translationLanguage) {
      setError("Please fill in all fields.");
      return;
    }
    if (!user || (user.role !== 'admin' && user.role !== 'editor')) {
      toast.error('You do not have permission to analyze text.');
      return;
    }
    setLoading(true);
    dispatch(analyzeText({ text, title, theme_id: theme, language: articleLanguage, translation_language: translationLanguage }))
      .then((response) => {
        const { message } = response.payload;
        if (message) {
          toast.info(message);
        } else {
          toast.success("Text analyzed successfully!");
        }
        navigate(-1);
        dispatch(setArticleText(text));
      })
      .catch((error) => {
        console.error("Error analyzing text:", error);
        toast.error("Failed to analyze text. Please try again.");
      })
      .finally(() => {
        setLoading(false);
        setError("");
      });
  };

  return (
    <Container>
      <Button onClick={() => navigate(-1)} color="secondary" startIcon={<i className="bi bi-arrow-left"></i>}>
        Back
      </Button>
      <Typography variant="h5" gutterBottom>Add Text</Typography>
      <form onSubmit={handleSubmit}>
        {/* Поле для ввода заголовка */}
        <TextField
          fullWidth
          label="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          margin="normal"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Title />
              </InputAdornment>
            ),
          }}
          sx={{ mt: 2 }}
        />

        {/* Поле выбора темы */}
        <TextField
          fullWidth
          select
          label="Theme"
          value={theme}
          onChange={(e) => setTheme(e.target.value)}
          margin="normal"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Subject />
              </InputAdornment>
            ),
          }}
          sx={{ mt: 2 }}
        >
          <MenuItem value="">Select theme</MenuItem>
          {themes.map((theme) => (
            <MenuItem key={theme.id} value={theme.id}>{theme.name}</MenuItem>
          ))}
        </TextField>

        {/* Поле выбора языка статьи */}
        <TextField
          fullWidth
          select
          label="Language"
          value={articleLanguage}
          onChange={(e) => setArticleLanguage(e.target.value)}
          margin="normal"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Description />
              </InputAdornment>
            ),
          }}
          sx={{ mt: 2 }}
        >
          <MenuItem value="de-DE">Deutsch (Deutschland)</MenuItem>
          <MenuItem value="ru-RU">Русский (Россия)</MenuItem>
          <MenuItem value="en-US">English (US)</MenuItem>
          <MenuItem value="es-ES">Spanish (Spain)</MenuItem>
          <MenuItem value="fr-FR">French (France)</MenuItem>
        </TextField>

        {/* Поле выбора языка перевода */}
        <TextField
          fullWidth
          select
          label="Translation Language"
          value={translationLanguage}
          onChange={(e) => setTranslationLanguage(e.target.value)}
          margin="normal"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Translate />
              </InputAdornment>
            ),
          }}
          sx={{ mt: 2 }}
        >
          <MenuItem value="de-DE">Deutsch (Deutschland)</MenuItem>
          <MenuItem value="ru-RU">Русский (Россия)</MenuItem>
          <MenuItem value="en-US">English (US)</MenuItem>
          <MenuItem value="es-ES">Spanish (Spain)</MenuItem>
          <MenuItem value="fr-FR">French (France)</MenuItem>
        </TextField>

        {/* Поле для ввода текста */}
        <Typography variant="body1" gutterBottom>Enter text here</Typography>
        <ReactQuill
          ref={quillRef}
          value={text}
          onChange={handleChange}
          modules={modules}
          formats={formats}
          style={{ marginBottom: '20px' }}
        />

        {/* Отображение ошибки */}
        {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}

        {/* Кнопка отправки */}
        <Button
          type="submit"
          disabled={loading}
          variant="contained"
          sx={{ mt: 2 }}
        >
          {loading ? <CircularProgress size={24} /> : "Analyze"}
        </Button>
      </form>

      {/* Контейнер для уведомлений */}
      <ToastContainer />
    </Container>
  );
};

export default AddTextPage;