import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { transformLang } from './Utils';
import {
  fetchWords,
  createWord,
  updateWord,
  deleteWord,
  clearWords,
} from '../redux/textsSlice';
import {
  Button,
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Modal,
  Box,
  TextField,
  Alert,
  Grid,
  Paper,
} from '@mui/material';
import {
  VolumeUp,
  Book,
  Comment,
  Lightbulb,
  ArrowBack,
  Edit,
  Delete,
  Check,
} from '@mui/icons-material';

const Dictionary = () => {
  const { sentenceId } = useParams();
  const dispatch = useDispatch();
  const words = useSelector((state) => state.texts.words);
  const user = useSelector((state) => state.auth.user); // Исправлено на state.auth.user

  const [selectedWord, setSelectedWord] = useState(null);
  const [newWord, setNewWord] = useState('');
  const [newTranslation, setNewTranslation] = useState('');
  const [newAudio, setNewAudio] = useState(null);
  const [currentListeningWordIndex, setCurrentListeningWordIndex] = useState(0);
  const [selectedTranslation, setSelectedTranslation] = useState(null);
  const [message, setMessage] = useState(null);

  const [showEditModal, setShowEditModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showListeningModal, setShowListeningModal] = useState(false);
  const [showSentenceModal, setShowSentenceModal] = useState(false);
  const [sentenceWords, setSentenceWords] = useState([]);
  const [userSentence, setUserSentence] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();
  const sentenceData = location.state;
  const sentenceText = sentenceData.text;

  useEffect(() => {
    dispatch(fetchWords(sentenceId));
  }, [sentenceId, dispatch]);

  const handleWordClick = (word) => {
    setSelectedWord(word);
    setShowEditModal(true);
  };

  const handleWordUpdate = () => {
    if (selectedWord) {
      dispatch(
        updateWord({
          wordId: selectedWord.id,
          text: selectedWord.text,
          translation: selectedWord.translation,
          audio: selectedWord.audio,
        })
      ).then(() => {
        setShowEditModal(false);
        setSelectedWord(null);
      });
    }
  };

  const handleWordDelete = (wordId) => {
    dispatch(deleteWord(wordId));
  };

  const handleCreateWord = () => {
    dispatch(
      createWord({
        sentenceId,
        text: newWord,
        translation: newTranslation,
        audio: newAudio,
      })
    ).then(() => {
      setNewWord('');
      setNewTranslation('');
      setNewAudio(null);
      setShowCreateModal(false);
    });
  };

  const handleListeningModeToggle = () => {
    setShowListeningModal(!showListeningModal);
    setCurrentListeningWordIndex(0);
    setSelectedTranslation(null);
  };

  const handleTranslationSelect = (translation) => {
    setSelectedTranslation(translation);
    if (translation === words[currentListeningWordIndex].translation) {
      setMessage({ text: 'Correct!', variant: 'success' });
      if (currentListeningWordIndex < words.length - 1) {
        setCurrentListeningWordIndex(currentListeningWordIndex + 1);
        setSelectedTranslation(null);
      } else {
        setMessage({ text: 'You have completed the listening exercise!', variant: 'success' });
        setShowListeningModal(false);
      }
    } else {
      setMessage({ text: 'Incorrect!', variant: 'error' });
    }
  };

  const getRandomTranslations = (word) => {
    const translations = words.filter((w) => w.id !== word.id).map((w) => w.translation);
    const randomTranslations = shuffleArray(translations).slice(0, 3);
    return shuffleArray([...randomTranslations, word.translation]);
  };

  const handleSentenceModeToggle = () => {
    setShowSentenceModal(!showSentenceModal);
    const sentenceWordsArray = sentenceText.split(' ');
    setSentenceWords(shuffleArray(sentenceWordsArray));
    setUserSentence([]);
  };

  const handleWordSelect = (word) => {
    setUserSentence([...userSentence, word]);
  };

  const handleCheckSentence = () => {
    const userSentenceText = userSentence.join(' ');
    const correctSentenceText = sentenceText;

    if (userSentenceText === correctSentenceText) {
      setMessage({ text: 'Correct!', variant: 'success' });
    } else {
      setMessage({ text: `Incorrect! The correct sentence is: ${correctSentenceText}`, variant: 'error' });
    }

    setShowSentenceModal(false);
  };

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const handlePlayAudio = (text, lang = user?.text_language, voice = user?.text_voice) => {
    if (!user || !user.text_language) {
      console.error("User data is not available or text_language is missing.");
      return;
    }

    let lang_tr = transformLang(lang);
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'speak', text: text, lang_tr, comp: ' ', voice: voice }));
    } else {
      const msg = new SpeechSynthesisUtterance(text);
      msg.lang = lang_tr;
      window.speechSynthesis.speak(msg);
    }
  };

  const handleFlashcards = () => {
    const sentences = words.map((word) => ({
      text: word.text,
      translation: word.translation,
    }));

    navigate('/flashcards', { state: { sentences } });
  };

  const handleSuggestiveTraining = () => {
    const sentences = words.map((word) => ({
      text: word.text,
      translation: word.translation,
    }));

    navigate('/suggestive-training', { state: { sentences } });
  };

  if (!user) {
    return <Typography>Loading user data...</Typography>;
  }

  return (
    <Container sx={{ padding: 0 }}>
      <IconButton onClick={() => navigate(-1)} color="secondary">
        <ArrowBack />
      </IconButton>

      {message && (
        <Alert severity={message.variant} onClose={() => setMessage(null)} sx={{ mt: 2 }}>
          {message.text}
        </Alert>
      )}

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 2, mb: 2 }}>
        <Typography variant="h5">{sentenceText}</Typography>
        <IconButton
          onClick={() => handlePlayAudio(sentenceText, user.text_language, user.text_voice)}
          color="primary"
        >
          <VolumeUp />
        </IconButton>
      </Box>

      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <IconButton onClick={handleListeningModeToggle} color="primary">
          <VolumeUp />
        </IconButton>
        <IconButton onClick={handleFlashcards} color="primary">
          <Book />
        </IconButton>
        <IconButton onClick={handleSentenceModeToggle} color="primary">
          <Comment />
        </IconButton>
        <IconButton onClick={handleSuggestiveTraining} color="primary">
          <Lightbulb />
        </IconButton>
      </Box>

      <Button variant="contained" onClick={() => setShowCreateModal(true)} sx={{ mb: 2 }}>
        Add New Word
      </Button>

      <List>
        {words.map((word) => (
          <ListItem
            key={word.id}
            sx={{ border: '1px solid #ddd', borderRadius: 1, mb: 1 }}
          >
            <ListItemText primary={`${word.text} - ${word.translation}`} />
            <Box sx={{ display: 'flex', gap: 1 }}>
              <IconButton onClick={() => handleWordClick(word)} color="warning">
                <Edit />
              </IconButton>
              <IconButton onClick={() => handleWordDelete(word.id)} color="error">
                <Delete />
              </IconButton>
            </Box>
          </ListItem>
        ))}
      </List>

      {/* Модальные окна */}
      <Modal open={showEditModal} onClose={() => setShowEditModal(false)}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
          <Typography variant="h6" mb={2}>Edit Word</Typography>
          {selectedWord && (
            <Box>
              <TextField
                fullWidth
                label="Word"
                value={selectedWord.text}
                onChange={(e) => setSelectedWord({ ...selectedWord, text: e.target.value })}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Translation"
                value={selectedWord.translation || ''}
                onChange={(e) => setSelectedWord({ ...selectedWord, translation: e.target.value })}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                type="file"
                onChange={(e) => setSelectedWord({ ...selectedWord, audio: e.target.files[0] })}
                sx={{ mb: 2 }}
              />
            </Box>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
            <Button variant="outlined" onClick={() => setShowEditModal(false)}>
              Close
            </Button>
            <Button variant="contained" onClick={handleWordUpdate}>
              Save Changes
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={showCreateModal} onClose={() => setShowCreateModal(false)}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
          <Typography variant="h6" mb={2}>Create New Word</Typography>
          <Box>
            <TextField
              fullWidth
              label="Word"
              value={newWord}
              onChange={(e) => setNewWord(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Translation"
              value={newTranslation}
              onChange={(e) => setNewTranslation(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              type="file"
              onChange={(e) => setNewAudio(e.target.files[0])}
              sx={{ mb: 2 }}
            />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
            <Button variant="outlined" onClick={() => setShowCreateModal(false)}>
              Close
            </Button>
            <Button variant="contained" onClick={handleCreateWord}>
              Create Word
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={showListeningModal} onClose={() => setShowListeningModal(false)}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
          <Typography variant="h6" mb={2}>Listening Mode</Typography>
          {words.length > 0 && (
            <Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <Button
                  variant="outlined"
                  onClick={() => handlePlayAudio(words[currentListeningWordIndex].text, user.text_language, user.text_voice)}
                  startIcon={<VolumeUp />}
                >
                  Play
                </Button>
              </Box>
              <Grid container spacing={2}>
                {getRandomTranslations(words[currentListeningWordIndex]).map((translation, index) => (
                  <Grid item xs={6} key={index}>
                    <Paper
                      sx={{
                        p: 2,
                        cursor: 'pointer',
                        backgroundColor: selectedTranslation === translation ? 'primary.main' : 'background.paper',
                        color: selectedTranslation === translation ? 'primary.contrastText' : 'text.primary',
                      }}
                      onClick={() => handleTranslationSelect(translation)}
                    >
                      {translation}
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button variant="outlined" onClick={() => setShowListeningModal(false)}>
              Close
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={showSentenceModal} onClose={() => setShowSentenceModal(false)}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
          <Typography variant="h6" mb={2}>Sentence Mode</Typography>
          {sentenceWords.length > 0 && (
            <Box>
              <Grid container spacing={2}>
                {sentenceWords.map((word, index) => (
                  <Grid item xs={6} key={index}>
                    <Paper
                      sx={{ p: 2, cursor: 'pointer' }}
                      onClick={() => handleWordSelect(word)}
                    >
                      {word}
                    </Paper>
                  </Grid>
                ))}
              </Grid>
              <Box sx={{ mt: 2 }}>
                <Typography variant="h6">Your Sentence:</Typography>
                <Typography>{userSentence.join(' ')}</Typography>
                <Button
                  variant="contained"
                  onClick={handleCheckSentence}
                  sx={{ mt: 2 }}
                >
                  Check Sentence
                </Button>
              </Box>
            </Box>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button variant="outlined" onClick={() => setShowSentenceModal(false)}>
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </Container>
  );
};

export default Dictionary;