import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCheckoutSession } from '../redux/textsSlice';
import styled from 'styled-components';
import api from '../axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Button = styled.button`
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const ErrorMessage = styled.div`
  color: red;
`;

const SubscriptionButton = () => {
  const dispatch = useDispatch();
  const error = useSelector((state) => state.texts.subscriptionError);
  const [publishableKey, setPublishableKey] = useState('');
  const [stripe, setStripe] = useState(null);

  useEffect(() => {
    const fetchPublishableKey = async () => {
      try {
        const response = await api.get('/stripe-publishable-key/');
        setPublishableKey(response.data.publishable_key);
      } catch (error) {
        console.error('Failed to fetch publishable key:', error);
        toast.error('Failed to fetch publishable key.');
      }
    };

    fetchPublishableKey();
  }, []);

  useEffect(() => {
    if (publishableKey) {
      const stripe = window.Stripe(publishableKey);
      setStripe(stripe);
    }
  }, [publishableKey]);

  const handleSubscribe = async () => {
    if (!stripe) {
      console.error('Stripe has not been loaded yet.');
      toast.error('Stripe has not been loaded yet.');
      return;
    }

    try {
      const response = await dispatch(createCheckoutSession()).unwrap();
      const sessionId = response.session_id;
      await stripe.redirectToCheckout({ sessionId });
      toast.success('Checkout session created successfully.');
    } catch (error) {
      console.error('Failed to create checkout session:', error);
      toast.error('Failed to create checkout session.');
    }
  };

  return (
    <>
      <Button onClick={handleSubscribe}>Subscribe</Button>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <ToastContainer />
    </>
  );
};

export default SubscriptionButton;