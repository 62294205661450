import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addCourse, deleteCourse } from "../redux/textsSlice";
import { useNavigate } from "react-router-dom";
import {
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  Box,
  Paper,
  Container,
} from "@mui/material";
import { Delete as DeleteIcon, ArrowBack as ArrowBackIcon } from "@mui/icons-material";

const ManageCoursesPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { courses, user } = useSelector((state) => ({
    courses: state.texts.courses,
    user: state.auth.user,
  }));

  const [courseName, setCourseName] = useState("");
  const [courseDescription, setCourseDescription] = useState("");
  const [isPublic, setIsPublic] = useState(false);

  const handleAddCourse = () => {
    dispatch(
      addCourse({
        name: courseName,
        description: courseDescription,
        created_by: user.id,
        is_public: isPublic,
      })
    );
    setCourseName("");
    setCourseDescription("");
    setIsPublic(false);
  };

  const handleDeleteCourse = (courseId) => {
    dispatch(deleteCourse(courseId));
  };

  return (
    <Container sx={{ mt: 0 }}>
      <Box sx={{ mb: 2 }}>
        <IconButton onClick={() => navigate(-1)} color="primary">
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="p" component="h5" sx={{ mt: 0 }}>
          Manage Courses
        </Typography>
      </Box>

      <Paper sx={{ p: 1, mb: 3 }}>
        <TextField
          fullWidth
          label="Course Name"
          value={courseName}
          onChange={(e) => setCourseName(e.target.value)}
          margin="normal"
          placeholder="Enter the course name"
        />
        <TextField
          fullWidth
          label="Course Description"
          value={courseDescription}
          onChange={(e) => setCourseDescription(e.target.value)}
          margin="normal"
          multiline
          rows={3}
          placeholder="Enter a course description"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isPublic}
              onChange={(e) => setIsPublic(e.target.checked)}
              color="primary"
            />
          }
          label="Public Course"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddCourse}
          sx={{ mt: 2 }}
        >
          Add Course
        </Button>
      </Paper>

      <Typography variant="p" component="h5" sx={{ mt: 4, mb: 2 }}>
        Courses
      </Typography>
      <List>
        {courses
          .filter((course) => course.created_by === user.id)
          .map((course) => (
            <Paper key={course.id} sx={{ mb: 1 }}>
              <ListItem>
                <ListItemText
                  primary={course.name}
                  secondary={course.description}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    onClick={() => handleDeleteCourse(course.id)}
                    color="error"
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </Paper>
          ))}
      </List>
    </Container>
  );
};

export default ManageCoursesPage;