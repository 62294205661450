import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { createTheme, deleteTheme, fetchThemes } from '../redux/textsSlice';
import {
  Box,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Typography,
} from '@mui/material';
import { Delete, ArrowBack} from '@mui/icons-material';

const ThemeManager = () => {
  const [newThemeName, setNewThemeName] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const themes = useSelector((state) => state.texts.themes);
  const courseId = useSelector((state) => state.texts.courseId); // Получаем courseId из Redux
  const selectedCourseName = useSelector((state) => state.texts.selectedCourseName);


  // Загружаем темы при изменении courseId
  useEffect(() => {
    if (courseId) {
      dispatch(fetchThemes(courseId));
    }
  }, [courseId, dispatch]);

  const handleCreateTheme = () => {
    if (!user || (user.role !== 'admin' && user.role !== 'editor')) {
      alert('You do not have permission to create themes.');
      return;
    }

    if (!newThemeName) {
      alert('Theme name is required.');
      return;
    }

    if (!courseId) {
      alert('Please select a course first.');
      return;
    }

    dispatch(createTheme({ name: newThemeName, course_id: courseId })).then(() => {
      dispatch(fetchThemes(courseId));
      setNewThemeName('');
    });
  };

  const handleDeleteTheme = (themeId) => {
    if (!user || (user.role !== 'admin' && user.role !== 'editor')) {
      alert('You do not have permission to delete themes.');
      return;
    }

    dispatch(deleteTheme(themeId)).then(() => {
      dispatch(fetchThemes(courseId));
    });
  };

  return (
    
    <Box sx={{ mt: 2 }}>

      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBack />
        </IconButton>
        <Typography variant="h5" sx={{ ml: 2 }}>
          {selectedCourseName}
        </Typography>
      </Box>


      {/* Поле для ввода названия новой темы */}
      <TextField
        fullWidth
        label="New Theme"
        value={newThemeName}
        onChange={(e) => setNewThemeName(e.target.value)}
        margin="normal"
        variant="outlined"
      />

      {/* Кнопка для создания темы */}
      <Button
        variant="contained"
        color="primary"
        onClick={handleCreateTheme}
        sx={{ mt: 2 }}
      >
        Create Theme
      </Button>

      {/* Список тем */}
      <List sx={{ mt: 3 }}>
        {themes.map((theme) => (
          <ListItem
            key={theme.id}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderBottom: '1px solid #e0e0e0',
            }}
          >
            <ListItemText primary={theme.name} />

            {/* Кнопка удаления темы */}
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() => handleDeleteTheme(theme.id)}
              color="error"
            >
              <Delete />
            </IconButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default ThemeManager;