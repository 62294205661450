// import React, { useState, useEffect } from 'react';
// import {
//   Box,
//   Typography,
//   Button,
//   FormControlLabel,
//   Switch,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   TextField,
// } from '@mui/material';
// import { useNavigate, useLocation } from 'react-router-dom';

// const CrosswordTrainingPage = () => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const { words } = location.state || { words: [] }; // Получаем слова из состояния навигации

//   // Состояние для выбора типа кроссворда
//   const [isVertical, setIsVertical] = useState(false);

//   // Проверка наличия данных
//   useEffect(() => {
//     if (!location.state || words.length === 0) {
//       navigate('/user-expressions'); // Перенаправляем обратно, если данных нет
//     }
//   }, [location.state, words, navigate]);

//   // Обрабатываем слова: сохраняем оригинальные и берем первую часть
//   const processedWords = words.map((word) => ({
//     ...word,
//     originalText: word.text, // Сохраняем оригинальное выражение
//     text: getFirstPartOfExpression(word.text), // Обрабатываем выражение
//   }));

//   // Разделяем слова на группы по 10 слов
//   const wordGroups = splitWordsIntoGroups(processedWords);
//   const [currentGroupIndex, setCurrentGroupIndex] = useState(0);

//   // Получаем текущую группу слов
//   const currentGroup = wordGroups[currentGroupIndex] || [];

//   // Преобразуем слова текущей группы в формат для кроссворда
//   const crosswordData = {
//     across: !isVertical
//       ? currentGroup.reduce((acc, word, index) => {
//           acc[index + 1] = {
//             clue: word.translation, // Вопрос — это перевод
//             answer: word.text.toUpperCase(), // Ответ — это первая часть слова (в верхнем регистре)
//             row: index, // Позиция слова в кроссворде
//             col: 0, // Все слова начинаются с первого столбца
//           };
//           return acc;
//         }, {})
//       : {}, // Если isVertical, across остается пустым
//     down: isVertical
//       ? currentGroup.reduce((acc, word, index) => {
//           acc[index + 1] = {
//             clue: word.translation, // Вопрос — это перевод
//             answer: word.text.toUpperCase(), // Ответ — это первая часть слова (в верхнем регистре)
//             row: 0, // Все слова начинаются с первой строки
//             col: index, // Каждое слово начинается с нового столбца
//           };
//           return acc;
//         }, {})
//       : {}, // Если !isVertical, down остается пустым
//   };

//   // Рассчитываем размер сетки
//   const calculateGridSize = (data) => {
//     let maxRow = 0;
//     let maxCol = 0;

//     // Проверяем across
//     Object.values(data.across).forEach((item) => {
//       maxRow = Math.max(maxRow, item.row);
//       maxCol = Math.max(maxCol, item.col + item.answer.length - 1);
//     });

//     // Проверяем down
//     Object.values(data.down).forEach((item) => {
//       maxRow = Math.max(maxRow, item.row + item.answer.length - 1);
//       maxCol = Math.max(maxCol, item.col);
//     });

//     return {
//       rows: maxRow + 1, // Количество строк
//       cols: maxCol + 1, // Количество столбцов
//     };
//   };

//   // Получаем размер сетки
//   const gridSize = calculateGridSize(crosswordData);

//   // Создаем пустую сетку
//   const [grid, setGrid] = useState(
//     Array.from({ length: gridSize.rows }, () =>
//       Array.from({ length: gridSize.cols }, () => '')
//     )
//   );

//   // Заполняем первую букву каждого слова
//   useEffect(() => {
//     const newGrid = [...grid];

//     // Заполняем первую букву для across
//     Object.values(crosswordData.across).forEach((item) => {
//       const { row, col, answer } = item;
//       newGrid[row][col] = answer[0]; // Первая буква слова
//     });

//     // Заполняем первую букву для down
//     Object.values(crosswordData.down).forEach((item) => {
//       const { row, col, answer } = item;
//       newGrid[row][col] = answer[0]; // Первая буква слова
//     });

//     setGrid(newGrid);
//   }, [crosswordData]);

//   // Обработчик изменения ячейки
//   const handleCellChange = (row, col, value) => {
//     const newGrid = [...grid];
//     newGrid[row][col] = value.toUpperCase();
//     setGrid(newGrid);
//   };

//   // Проверка правильности ответов
//   const checkAnswers = () => {
//     const newGrid = [...grid];

//     // Проверяем across
//     Object.values(crosswordData.across).forEach((item) => {
//       const { row, col, answer } = item;
//       for (let i = 0; i < answer.length; i++) {
//         const cellRow = row;
//         const cellCol = col + i;
//         if (newGrid[cellRow][cellCol] === answer[i]) {
//           newGrid[cellRow][cellCol] = {
//             value: newGrid[cellRow][cellCol],
//             correct: true,
//           };
//         } else {
//           newGrid[cellRow][cellCol] = {
//             value: newGrid[cellRow][cellCol],
//             correct: false,
//           };
//         }
//       }
//     });

//     // Проверяем down
//     Object.values(crosswordData.down).forEach((item) => {
//       const { row, col, answer } = item;
//       for (let i = 0; i < answer.length; i++) {
//         const cellRow = row + i;
//         const cellCol = col;
//         if (newGrid[cellRow][cellCol] === answer[i]) {
//           newGrid[cellRow][cellCol] = {
//             value: newGrid[cellRow][cellCol],
//             correct: true,
//           };
//         } else {
//           newGrid[cellRow][cellCol] = {
//             value: newGrid[cellRow][cellCol],
//             correct: false,
//           };
//         }
//       }
//     });

//     setGrid(newGrid);
//   };

//   // Обработчик для перехода к следующей группе
//   const handleNextGroup = () => {
//     if (currentGroupIndex < wordGroups.length - 1) {
//       setCurrentGroupIndex(currentGroupIndex + 1);
//     } else {
//       alert('You have completed all groups!');
//     }
//   };

//   // Проверка, что данные не пусты
//   if (currentGroup.length === 0) {
//     return (
//       <Box sx={{ p: 3 }}>
//         <Typography variant="h4" sx={{ mb: 3 }}>
//           Crossword Training
//         </Typography>
//         <Typography variant="body1" sx={{ mb: 3 }}>
//           No words available for this group.
//         </Typography>
//         <Button
//           variant="contained"
//           onClick={() => navigate(-1)} // Кнопка для возврата назад
//         >
//           Back to Expressions
//         </Button>
//       </Box>
//     );
//   }

//   return (
//     <Box sx={{ p: 3 }}>
//       <Typography variant="h4" sx={{ mb: 3 }}>
//         Crossword Training (Group {currentGroupIndex + 1} of {wordGroups.length})
//       </Typography>
//       <Button
//         variant="contained"
//         sx={{ mb: 3, mr: 2 }}
//         onClick={() => navigate(-1)} // Кнопка для возврата назад
//       >
//         Back to Expressions
//       </Button>
//       <Button
//         variant="contained"
//         sx={{ mb: 3, mr: 2 }}
//         onClick={handleNextGroup} // Кнопка для перехода к следующей группе
//       >
//         Next Group
//       </Button>
//       <Button
//         variant="contained"
//         sx={{ mb: 3, mr: 2 }}
//         onClick={checkAnswers} // Кнопка для проверки ответов
//       >
//         Проверить
//       </Button>
//       <FormControlLabel
//         control={
//           <Switch
//             checked={isVertical}
//             onChange={(e) => setIsVertical(e.target.checked)} // Переключатель для выбора типа кроссворда
//           />
//         }
//         label="Vertical Words"
//         sx={{ mb: 3 }}
//       />
//       {/* Отображаем кроссворд */}
//       <Box sx={{ display: 'flex', gap: 4 }}>
//         {/* Сетка кроссворда */}
//         <TableContainer component={Paper}>
//           <Table>
//             <TableBody>
//               {grid.map((row, rowIndex) => (
//                 <TableRow key={rowIndex}>
//                   {row.map((cell, colIndex) => (
//                     <TableCell
//                       key={colIndex}
//                       sx={{
//                         border: '1px solid black',
//                         width: '40px',
//                         height: '40px',
//                         padding: 0,
//                         backgroundColor:
//                           cell.correct === true
//                             ? '#a5d6a7' // Зеленый для правильных ответов
//                             : cell.correct === false
//                             ? '#ef9a9a' // Красный для неправильных ответов
//                             : 'inherit',
//                       }}
//                     >
//                       <TextField
//                         value={typeof cell === 'string' ? cell : cell.value}
//                         onChange={(e) =>
//                           handleCellChange(rowIndex, colIndex, e.target.value)
//                         }
//                         inputProps={{
//                           maxLength: 1,
//                           style: {
//                             textAlign: 'center',
//                             textTransform: 'uppercase',
//                           },
//                         }}
//                         sx={{
//                           width: '100%',
//                           height: '100%',
//                           '& input': {
//                             padding: '8px',
//                             textAlign: 'center',
//                           },
//                         }}
//                       />
//                     </TableCell>
//                   ))}
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>

//         {/* Подсказки */}
//         <Box>
//           <Typography variant="h6" sx={{ mb: 2 }}>
//             Подсказки:
//           </Typography>
//           {Object.entries(crosswordData.across).map(([number, clue]) => (
//             <Typography key={`across-${number}`} variant="body1">
//               {number} Across: {clue.clue}
//             </Typography>
//           ))}
//           {Object.entries(crosswordData.down).map(([number, clue]) => (
//             <Typography key={`down-${number}`} variant="body1">
//               {number} Down: {clue.clue}
//             </Typography>
//           ))}
//         </Box>
//       </Box>
//     </Box>
//   );
// };

// // Функция для разделения слов на группы
// const splitWordsIntoGroups = (words, groupSize = 10) => {
//   const groups = [];
//   for (let i = 0; i < words.length; i += groupSize) {
//     groups.push(words.slice(i, i + groupSize));
//   }
//   return groups;
// };

// // Функция для получения первой части выражения (до разделителя)
// const getFirstPartOfExpression = (expression) => {
//   if (!expression) return ''; // Проверка на пустую строку
//   const separators = [',', ';', '-'];
//   let firstPart = expression;

//   for (const separator of separators) {
//     if (expression.includes(separator)) {
//       firstPart = expression.split(separator)[0].trim();
//       break;
//     }
//   }

//   return firstPart;
// };

// export default CrosswordTrainingPage;


// import React, { useState, useEffect } from 'react';
// import {
//   Box,
//   Typography,
//   Button,
//   FormControlLabel,
//   Switch,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   TextField,
//   IconButton, // Добавляем IconButton
// } from '@mui/material';
// import { useNavigate, useLocation } from 'react-router-dom';
// import AddIcon from '@mui/icons-material/Add'; // Импортируем иконку "+"

// const CrosswordTrainingPage = () => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const { words } = location.state || { words: [] }; // Получаем слова из состояния навигации

//   // Состояние для выбора типа кроссворда
//   const [isVertical, setIsVertical] = useState(false);

//   // Проверка наличия данных
//   useEffect(() => {
//     if (!location.state || words.length === 0) {
//       navigate('/user-expressions'); // Перенаправляем обратно, если данных нет
//     }
//   }, [location.state, words, navigate]);

//   // Обрабатываем слова: сохраняем оригинальные и берем первую часть
//   const processedWords = words.map((word) => ({
//     ...word,
//     originalText: word.text, // Сохраняем оригинальное выражение
//     text: getFirstPartOfExpression(word.text), // Обрабатываем выражение
//   }));

//   // Разделяем слова на группы по 10 слов
//   const wordGroups = splitWordsIntoGroups(processedWords);
//   const [currentGroupIndex, setCurrentGroupIndex] = useState(0);

//   // Получаем текущую группу слов
//   const currentGroup = wordGroups[currentGroupIndex] || [];

//   // Преобразуем слова текущей группы в формат для кроссворда
//   const crosswordData = {
//     across: !isVertical
//       ? currentGroup.reduce((acc, word, index) => {
//           acc[index + 1] = {
//             clue: word.translation, // Вопрос — это перевод
//             answer: word.text.toUpperCase(), // Ответ — это первая часть слова (в верхнем регистре)
//             row: index, // Позиция слова в кроссворде
//             col: 0, // Все слова начинаются с первого столбца
//           };
//           return acc;
//         }, {})
//       : {}, // Если isVertical, across остается пустым
//     down: isVertical
//       ? currentGroup.reduce((acc, word, index) => {
//           acc[index + 1] = {
//             clue: word.translation, // Вопрос — это перевод
//             answer: word.text.toUpperCase(), // Ответ — это первая часть слова (в верхнем регистре)
//             row: 0, // Все слова начинаются с первой строки
//             col: index, // Каждое слово начинается с нового столбца
//           };
//           return acc;
//         }, {})
//       : {}, // Если !isVertical, down остается пустым
//   };

//   // Рассчитываем размер сетки
//   const calculateGridSize = (data) => {
//     let maxRow = 0;
//     let maxCol = 0;

//     // Проверяем across
//     Object.values(data.across).forEach((item) => {
//       maxRow = Math.max(maxRow, item.row);
//       maxCol = Math.max(maxCol, item.col + item.answer.length - 1);
//     });

//     // Проверяем down
//     Object.values(data.down).forEach((item) => {
//       maxRow = Math.max(maxRow, item.row + item.answer.length - 1);
//       maxCol = Math.max(maxCol, item.col);
//     });

//     return {
//       rows: maxRow + 1, // Количество строк
//       cols: maxCol + 1, // Количество столбцов
//     };
//   };

//   // Получаем размер сетки
//   const gridSize = calculateGridSize(crosswordData);

//   // Создаем пустую сетку
//   const [grid, setGrid] = useState(
//     Array.from({ length: gridSize.rows }, () =>
//       Array.from({ length: gridSize.cols }, () => '')
//     )
//   );

//   // Обновляем сетку при изменении типа кроссворда или группы слов
//   useEffect(() => {
//     const newGrid = Array.from({ length: gridSize.rows }, () =>
//       Array.from({ length: gridSize.cols }, () => '')
//     );

//     // Заполняем первую букву для across
//     Object.values(crosswordData.across).forEach((item) => {
//       const { row, col, answer } = item;
//       newGrid[row][col] = answer[0]; // Первая буква слова
//     });

//     // Заполняем первую букву для down
//     Object.values(crosswordData.down).forEach((item) => {
//       const { row, col, answer } = item;
//       newGrid[row][col] = answer[0]; // Первая буква слова
//     });

//     setGrid(newGrid);
//   }, [isVertical, currentGroupIndex]);

//   // Обработчик изменения ячейки
//   const handleCellChange = (row, col, value) => {
//     const newGrid = [...grid];
//     newGrid[row][col] = value.toUpperCase();
//     setGrid(newGrid);
//   };

//   // Проверка правильности ответов
//   const checkAnswers = () => {
//     const newGrid = [...grid];

//     // Проверяем across
//     Object.values(crosswordData.across).forEach((item) => {
//       const { row, col, answer } = item;
//       for (let i = 0; i < answer.length; i++) {
//         const cellRow = row;
//         const cellCol = col + i;
//         const cellValue = typeof newGrid[cellRow][cellCol] === 'string' 
//           ? newGrid[cellRow][cellCol] 
//           : newGrid[cellRow][cellCol].value;

//         if (cellValue === answer[i]) {
//           newGrid[cellRow][cellCol] = {
//             value: cellValue,
//             correct: true,
//           };
//         } else {
//           newGrid[cellRow][cellCol] = {
//             value: cellValue,
//             correct: false,
//           };
//         }
//       }
//     });

//     // Проверяем down
//     Object.values(crosswordData.down).forEach((item) => {
//       const { row, col, answer } = item;
//       for (let i = 0; i < answer.length; i++) {
//         const cellRow = row + i;
//         const cellCol = col;
//         const cellValue = typeof newGrid[cellRow][cellCol] === 'string' 
//           ? newGrid[cellRow][cellCol] 
//           : newGrid[cellRow][cellCol].value;

//         if (cellValue === answer[i]) {
//           newGrid[cellRow][cellCol] = {
//             value: cellValue,
//             correct: true,
//           };
//         } else {
//           newGrid[cellRow][cellCol] = {
//             value: cellValue,
//             correct: false,
//           };
//         }
//       }
//     });

//     setGrid(newGrid);
//   };

//   // Обработчик для перехода к следующей группе
//   const handleNextGroup = () => {
//     if (currentGroupIndex < wordGroups.length - 1) {
//       setCurrentGroupIndex(currentGroupIndex + 1);
//     } else {
//       alert('You have completed all groups!');
//     }
//   };

//   // Обработчик для добавления следующей буквы в строку
//   const handleAddNextLetter = (rowIndex) => {
//     const newGrid = [...grid];
//     const word = crosswordData.across[rowIndex + 1] || crosswordData.down[rowIndex + 1];

//     if (word) {
//       const { row, col, answer } = word;
//       for (let i = 0; i < answer.length; i++) {
//         const cellRow = isVertical ? row + i : row;
//         const cellCol = isVertical ? col : col + i;

//         if (newGrid[cellRow][cellCol] === '') {
//           newGrid[cellRow][cellCol] = answer[i];
//           break;
//         }
//       }
//     }

//     setGrid(newGrid);
//   };

//   // Проверка, что данные не пусты
//   if (currentGroup.length === 0) {
//     return (
//       <Box sx={{ p: 3 }}>
//         <Typography variant="h4" sx={{ mb: 3 }}>
//           Crossword Training
//         </Typography>
//         <Typography variant="body1" sx={{ mb: 3 }}>
//           No words available for this group.
//         </Typography>
//         <Button
//           variant="contained"
//           onClick={() => navigate(-1)} // Кнопка для возврата назад
//         >
//           Back to Expressions
//         </Button>
//       </Box>
//     );
//   }

//   return (
//     <Box sx={{ p: 3 }}>
//       <Typography variant="h4" sx={{ mb: 3 }}>
//         Crossword Training (Group {currentGroupIndex + 1} of {wordGroups.length})
//       </Typography>
//       <Button
//         variant="contained"
//         sx={{ mb: 3, mr: 2 }}
//         onClick={() => navigate(-1)} // Кнопка для возврата назад
//       >
//         Back to Expressions
//       </Button>
//       <Button
//         variant="contained"
//         sx={{ mb: 3, mr: 2 }}
//         onClick={handleNextGroup} // Кнопка для перехода к следующей группе
//       >
//         Next Group
//       </Button>
//       <Button
//         variant="contained"
//         sx={{ mb: 3, mr: 2 }}
//         onClick={checkAnswers} // Кнопка для проверки ответов
//       >
//         Проверить
//       </Button>
//       <FormControlLabel
//         control={
//           <Switch
//             checked={isVertical}
//             onChange={(e) => setIsVertical(e.target.checked)} // Переключатель для выбора типа кроссворда
//           />
//         }
//         label="Vertical Words"
//         sx={{ mb: 3 }}
//       />
//       {/* Отображаем кроссворд */}
//       <Box sx={{ display: 'flex', gap: 4 }}>
//         {/* Сетка кроссворда */}
//         <TableContainer component={Paper}>
//           <Table>
//             <TableBody>
//               {grid.map((row, rowIndex) => (
//                 <TableRow key={rowIndex}>
//                   {/* Кнопка для добавления следующей буквы */}
//                   <TableCell sx={{ padding: 0, width: '40px' }}>
//                     <IconButton
//                       size="small" // Уменьшаем размер кнопки
//                       onClick={() => handleAddNextLetter(rowIndex)}
//                       sx={{ width: '100%', height: '100%' }}
//                     >
//                       <AddIcon /> {/* Используем иконку "+" */}
//                     </IconButton>
//                   </TableCell>
//                   {row.map((cell, colIndex) => (
//                     <TableCell
//                       key={colIndex}
//                       sx={{
//                         border: '1px solid black',
//                         width: '40px',
//                         height: '40px',
//                         padding: 0,
//                         backgroundColor:
//                           cell.correct === true
//                             ? '#a5d6a7' // Зеленый для правильных ответов
//                             : cell.correct === false
//                             ? '#ef9a9a' // Красный для неправильных ответов
//                             : 'inherit',
//                       }}
//                     >
//                       <TextField
//                         value={typeof cell === 'string' ? cell : cell.value} // Всегда используем строковое значение
//                         onChange={(e) =>
//                           handleCellChange(rowIndex, colIndex, e.target.value)
//                         }
//                         inputProps={{
//                           maxLength: 1,
//                           style: {
//                             textAlign: 'center',
//                             textTransform: 'uppercase',
//                           },
//                         }}
//                         sx={{
//                           width: '100%',
//                           height: '100%',
//                           '& input': {
//                             padding: '8px',
//                             textAlign: 'center',
//                           },
//                         }}
//                       />
//                     </TableCell>
//                   ))}
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>

//         {/* Подсказки */}
//         <Box>
//           <Typography variant="h6" sx={{ mb: 2 }}>
//             Подсказки:
//           </Typography>
//           {Object.entries(crosswordData.across).map(([number, clue]) => (
//             <Typography key={`across-${number}`} variant="body1">
//               {number} Across: {clue.clue}
//             </Typography>
//           ))}
//           {Object.entries(crosswordData.down).map(([number, clue]) => (
//             <Typography key={`down-${number}`} variant="body1">
//               {number} Down: {clue.clue}
//             </Typography>
//           ))}
//         </Box>
//       </Box>
//     </Box>
//   );
// };

// // Функция для разделения слов на группы
// const splitWordsIntoGroups = (words, groupSize = 10) => {
//   const groups = [];
//   for (let i = 0; i < words.length; i += groupSize) {
//     groups.push(words.slice(i, i + groupSize));
//   }
//   return groups;
// };

// // Функция для получения первой части выражения (до разделителя)
// const getFirstPartOfExpression = (expression) => {
//   if (!expression) return ''; // Проверка на пустую строку
//   const separators = [',', ';', '-'];
//   let firstPart = expression;

//   for (const separator of separators) {
//     if (expression.includes(separator)) {
//       firstPart = expression.split(separator)[0].trim();
//       break;
//     }
//   }

//   return firstPart;
// };

// export default CrosswordTrainingPage;



import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  IconButton,
  Grid,
  useMediaQuery,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import ArrowBack from '@mui/icons-material/ArrowBack'; // Иконка для "Предыдущая группа"
import ArrowForward from '@mui/icons-material/ArrowForward'; // Иконка для "Следующая группа"

const CrosswordTrainingPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { words } = location.state || { words: [] };

  const [isVertical, setIsVertical] = useState(false);

  // Определяем, является ли экран маленьким
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  // Автоматически устанавливаем вертикальный режим на маленьких экранах
  useEffect(() => {
    if (isSmallScreen) {
      setIsVertical(true);
    }
  }, [isSmallScreen]);

  useEffect(() => {
    if (!location.state || words.length === 0) {
      navigate('/user-expressions');
    }
  }, [location.state, words, navigate]);

  const processedWords = words.map((word) => ({
    ...word,
    originalText: word.text,
    text: getFirstPartOfExpression(word.text),
  }));

  const wordGroups = splitWordsIntoGroups(processedWords);
  const [currentGroupIndex, setCurrentGroupIndex] = useState(0);

  const currentGroup = wordGroups[currentGroupIndex] || [];

  const crosswordData = {
    across: !isVertical
      ? currentGroup.reduce((acc, word, index) => {
          acc[index + 1] = {
            clue: word.translation,
            answer: word.text.toUpperCase(),
            row: index,
            col: 0,
          };
          return acc;
        }, {})
      : {},
    down: isVertical
      ? currentGroup.reduce((acc, word, index) => {
          acc[index + 1] = {
            clue: word.translation,
            answer: word.text.toUpperCase(),
            row: 0,
            col: index,
          };
          return acc;
        }, {})
      : {},
  };

  const calculateGridSize = (data) => {
    let maxRow = 0;
    let maxCol = 0;

    Object.values(data.across).forEach((item) => {
      maxRow = Math.max(maxRow, item.row);
      maxCol = Math.max(maxCol, item.col + item.answer.length - 1);
    });

    Object.values(data.down).forEach((item) => {
      maxRow = Math.max(maxRow, item.row + item.answer.length - 1);
      maxCol = Math.max(maxCol, item.col);
    });

    return {
      rows: maxRow + 1,
      cols: maxCol + 1,
    };
  };

  const gridSize = calculateGridSize(crosswordData);

  const [grid, setGrid] = useState(
    Array.from({ length: gridSize.rows }, () =>
      Array.from({ length: gridSize.cols }, () => '')
    )
  );

  useEffect(() => {
    const newGrid = Array.from({ length: gridSize.rows }, () =>
      Array.from({ length: gridSize.cols }, () => '')
    );

    Object.values(crosswordData.across).forEach((item) => {
      const { row, col, answer } = item;
      newGrid[row][col] = answer[0];
    });

    Object.values(crosswordData.down).forEach((item) => {
      const { row, col, answer } = item;
      newGrid[row][col] = answer[0];
    });

    setGrid(newGrid);
  }, [isVertical, currentGroupIndex]);

  const handleCellChange = (row, col, value) => {
    const newGrid = [...grid];
    newGrid[row][col] = value.toUpperCase();
    setGrid(newGrid);
  };

  const checkAnswers = () => {
    const newGrid = [...grid];

    Object.values(crosswordData.across).forEach((item) => {
      const { row, col, answer } = item;
      for (let i = 0; i < answer.length; i++) {
        const cellRow = row;
        const cellCol = col + i;
        const cellValue = typeof newGrid[cellRow][cellCol] === 'string' 
          ? newGrid[cellRow][cellCol] 
          : newGrid[cellRow][cellCol].value;

        if (cellValue === answer[i]) {
          newGrid[cellRow][cellCol] = {
            value: cellValue,
            correct: true,
          };
        } else {
          newGrid[cellRow][cellCol] = {
            value: cellValue,
            correct: false,
          };
        }
      }
    });

    Object.values(crosswordData.down).forEach((item) => {
      const { row, col, answer } = item;
      for (let i = 0; i < answer.length; i++) {
        const cellRow = row + i;
        const cellCol = col;
        const cellValue = typeof newGrid[cellRow][cellCol] === 'string' 
          ? newGrid[cellRow][cellCol] 
          : newGrid[cellRow][cellCol].value;

        if (cellValue === answer[i]) {
          newGrid[cellRow][cellCol] = {
            value: cellValue,
            correct: true,
          };
        } else {
          newGrid[cellRow][cellCol] = {
            value: cellValue,
            correct: false,
          };
        }
      }
    });

    setGrid(newGrid);
  };

  const handleNextGroup = () => {
    if (currentGroupIndex < wordGroups.length - 1) {
      setCurrentGroupIndex(currentGroupIndex + 1);
    } else {
      alert('You have completed all groups!');
    }
  };

  const handlePreviousGroup = () => {
    if (currentGroupIndex > 0) {
      setCurrentGroupIndex(currentGroupIndex - 1);
    } else {
      alert('This is the first group!');
    }
  };

  const handleAddNextLetter = (index) => {
    const newGrid = [...grid];
    const word = isVertical
      ? crosswordData.down[index + 1]
      : crosswordData.across[index + 1];

    if (word) {
      const { row, col, answer } = word;
      for (let i = 0; i < answer.length; i++) {
        const cellRow = isVertical ? row + i : row;
        const cellCol = isVertical ? col : col + i;

        if (newGrid[cellRow][cellCol] === '') {
          newGrid[cellRow][cellCol] = answer[i];
          break;
        }
      }
    }

    setGrid(newGrid);
  };

  if (currentGroup.length === 0) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography variant="h4" sx={{ mb: 3 }}>
          Crossword
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          No words available for this group.
        </Typography>
        <Button
          variant="contained"
          onClick={() => navigate(-1)}
        >
          Back to Expressions
        </Button>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 0 }}>

     <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <IconButton color="primary" onClick={() => navigate(-1)}>
          <ArrowBack />
        </IconButton>
        <Typography variant="h5" sx={{ ml: 2 }}>
        Crossword
        </Typography>
      </Box>


      <Typography variant="h6" sx={{ mb: 0 }}>
        (Group {currentGroupIndex + 1} of {wordGroups.length})
      </Typography>

      {/* Кнопка "Предыдущая группа" в виде IconButton */}
      <IconButton
        color="primary"
        onClick={handlePreviousGroup}
        sx={{ mb: 3, mr: 2 }}
      >
        <ArrowBack />
      </IconButton>
      {/* Кнопка "Следующая группа" в виде IconButton */}
      <Button
        variant="contained"
        sx={{ mb: 3, mr: 2 }}
        onClick={checkAnswers}
      >
        Check
      </Button>
      <IconButton
        color="primary"
        onClick={handleNextGroup}
        sx={{ mb: 3, mr: 2 }}
      >
        <ArrowForward />
      </IconButton>


      {/* Скрываем переключатель на маленьких экранах */}
      {!isSmallScreen && (
        <FormControlLabel
          control={
            <Switch
              checked={isVertical}
              onChange={(e) => setIsVertical(e.target.checked)}
            />
          }
          label="Vertical Words"
          sx={{ mb: 3 }}
        />
      )}
      {/* Адаптивный контейнер для кроссворда и подсказок */}
      <Grid container spacing={2}>
        {/* Подсказки на маленьких экранах */}
        {isSmallScreen && (
          <Grid item xs={12}>
            <Box>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Подсказки:
              </Typography>
              {Object.entries(crosswordData.across).map(([number, clue]) => (
                <Typography key={`across-${number}`} variant="body1">
                  {number} Across: {clue.clue}
                </Typography>
              ))}
              {Object.entries(crosswordData.down).map(([number, clue]) => (
                <Typography key={`down-${number}`} variant="body1">
                  {number} Down: {clue.clue}
                </Typography>
              ))}
            </Box>
          </Grid>
        )}
        {/* Сетка кроссворда */}
        <Grid item xs={12} md={isSmallScreen ? 12 : 8}>
          <TableContainer component={Paper}>
            <Table>
              {/* Кнопки + сверху в вертикальном режиме */}
              {isVertical && (
                <TableHead>
                  <TableRow>
                    {Array.from({ length: gridSize.cols }, (_, colIndex) => (
                      <TableCell key={colIndex} sx={{ padding: 0, width: '40px' }}>
                        <IconButton
                          size="small"
                          onClick={() => handleAddNextLetter(colIndex)}
                          sx={{ width: '100%', height: '100%' }}
                        >
                          <AddIcon />
                        </IconButton>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
              )}
              <TableBody>
                {grid.map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {/* Кнопки + слева в горизонтальном режиме */}
                    {!isVertical && (
                      <TableCell sx={{ padding: 0, width: '40px' }}>
                        <IconButton
                          size="small"
                          onClick={() => handleAddNextLetter(rowIndex)}
                          sx={{ width: '100%', height: '100%' }}
                        >
                          <AddIcon />
                        </IconButton>
                      </TableCell>
                    )}
                    {row.map((cell, colIndex) => (
                      <TableCell
                        key={colIndex}
                        sx={{
                          border: '1px solid black',
                          width: '40px',
                          height: '40px',
                          padding: 0,
                          backgroundColor:
                            cell.correct === true
                              ? '#a5d6a7'
                              : cell.correct === false
                              ? '#ef9a9a'
                              : 'inherit',
                        }}
                      >
                        <TextField
                          value={typeof cell === 'string' ? cell : cell.value}
                          onChange={(e) =>
                            handleCellChange(rowIndex, colIndex, e.target.value)
                          }
                          inputProps={{
                            maxLength: 1,
                            style: {
                              textAlign: 'center',
                              textTransform: 'uppercase',
                            },
                          }}
                          sx={{
                            width: '100%',
                            height: '100%',
                            '& input': {
                              padding: '8px',
                              textAlign: 'center',
                            },
                          }}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {/* Подсказки на больших экранах */}
        {!isSmallScreen && (
          <Grid item xs={12} md={4}>
            <Box>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Подсказки:
              </Typography>
              {Object.entries(crosswordData.across).map(([number, clue]) => (
                <Typography key={`across-${number}`} variant="body1">
                  {number} Across: {clue.clue}
                </Typography>
              ))}
              {Object.entries(crosswordData.down).map(([number, clue]) => (
                <Typography key={`down-${number}`} variant="body1">
                  {number} Down: {clue.clue}
                </Typography>
              ))}
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

const splitWordsIntoGroups = (words, groupSize = 10) => {
  const groups = [];
  for (let i = 0; i < words.length; i += groupSize) {
    groups.push(words.slice(i, i + groupSize));
  }
  return groups;
};

const getFirstPartOfExpression = (expression) => {
  if (!expression) return '';
  const separators = [',', ';', '-'];
  let firstPart = expression;

  for (const separator of separators) {
    if (expression.includes(separator)) {
      firstPart = expression.split(separator)[0].trim();
      break;
    }
  }

  return firstPart;
};

export default CrosswordTrainingPage;