import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { transformLang } from './Utils';
import { Button, Card, CardContent, Typography, IconButton, Box } from '@mui/material';
import { ArrowBack, VolumeUp, ArrowForward } from '@mui/icons-material';
import './Flashcard.css';


const FlashcardsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state) => state.auth.user);
  const { sentences } = location.state || {};
  const [currentFlashcardIndex, setCurrentFlashcardIndex] = useState(0);
  const [isFlipped, setIsFlipped] = useState(false);

  const currentFlashcard = sentences?.[currentFlashcardIndex];

  const handleNextFlashcard = () => {
    if (currentFlashcardIndex < sentences.length - 1) {
      setCurrentFlashcardIndex(currentFlashcardIndex + 1);
      setIsFlipped(false);
    }
  };

  const handlePreviousFlashcard = () => {
    if (currentFlashcardIndex > 0) {
      setCurrentFlashcardIndex(currentFlashcardIndex - 1);
      setIsFlipped(false);
    }
  };

  const handlePlayAudio = (text, lang, voice) => {
    if (!user || !lang || !voice) {
      console.error("User data, language, or voice is missing.");
      return;
    }

    const lang_tr = transformLang(lang);
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ type: 'speak', text: text, lang: lang_tr, comp: ' ', voice: voice })
      );
    } else {
      const msg = new SpeechSynthesisUtterance(text);
      msg.lang = lang_tr;
      window.speechSynthesis.speak(msg);
    }
  };

  return (
    <Box sx={{ padding: 0 }}>

      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBack />
        </IconButton>
        <Typography variant="h5" sx={{ ml: 2 }}>
        Flashcards
        </Typography>
      </Box>


      <Box
        sx={{
          perspective: '1000px',
          width: '100%',
          height: '50vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {sentences?.length > 0 && currentFlashcard ? (
          <Box sx={{ width: '100%', maxWidth: 600, height: 400 }}>
            {/* Контейнер для карточки */}
            <Box
              sx={{
                width: '100%',
                height: '100%',
                position: 'relative',
                transformStyle: 'preserve-3d',
                transition: 'transform 0.6s',
                cursor: 'pointer',
                transform: isFlipped ? 'rotateY(180deg)' : 'rotateY(0)',
              }}
              onClick={() => setIsFlipped(!isFlipped)}
            >
              {/* Лицевая сторона карточки */}
              <Card
                sx={{
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  backfaceVisibility: 'hidden',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: '1px solid var(--border-color)',
                  borderRadius: '10px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  backgroundColor: 'var(--background-color)',
                  color: 'var(--text-color)',
                }}
              >
                <CardContent>
                  <Typography variant="h5" align="center">
                    {currentFlashcard.text}
                  </Typography>
                </CardContent>
              </Card>
              {/* Обратная сторона карточки */}
              <Card
                sx={{
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  backfaceVisibility: 'hidden',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: '1px solid var(--border-color)',
                  borderRadius: '10px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  backgroundColor: 'var(--background-color)',
                  color: 'var(--text-color)',
                  transform: 'rotateY(180deg)', // Переворот на 180 градусов
                }}
              >
                <CardContent>
                  <Typography variant="h5" align="center">
                    {currentFlashcard.translation}
                  </Typography>
                </CardContent>
              </Card>
            </Box>
            {/* Управление карточками */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                marginTop: '20px',
              }}
            >
              <Button
                variant="outlined"
                onClick={handlePreviousFlashcard}
                disabled={currentFlashcardIndex === 0}
                startIcon={<ArrowBack />}
                sx={{
                  borderColor: 'var(--primary-color)',
                  color: 'var(--primary-color)',
                  '&:hover': {
                    backgroundColor: 'var(--primary-color)',
                    color: 'var(--background-color)',
                  },
                }}
              >
                Previous
              </Button>
              <IconButton
                onClick={() =>
                  handlePlayAudio(
                    isFlipped ? currentFlashcard.translation : currentFlashcard.text,
                    isFlipped ? user.translation_language : user.text_language,
                    isFlipped ? user.translation_voice : user.text_voice
                  )
                }
                sx={{
                  color: 'var(--primary-color)',
                }}
              >
                <VolumeUp />
              </IconButton>
              <Button
                variant="contained"
                onClick={handleNextFlashcard}
                disabled={currentFlashcardIndex === sentences.length - 1}
                endIcon={<ArrowForward />}
                sx={{
                  backgroundColor: 'var(--primary-color)',
                  color: 'var(--text-color)',
                  '&:hover': {
                    backgroundColor: '#e67339', // Более темный оранжевый
                    borderColor: '#e67339',
                  },
                }}
              >
                Next
              </Button>
            </Box>
          </Box>
        ) : (
          <Typography variant="body1" align="center">
            No expressions available for this theme.
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default FlashcardsPage;