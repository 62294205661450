import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCourses, setCourseId, setSelectedCourseName } from '../redux/textsSlice'; 
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './HomePage.css';
import { createSelector } from 'reselect';
import { Typography, Paper, Box, Divider } from '@mui/material';

// Компонент заголовка панели
const PanelHeading = ({ title }) => (
  <Box sx={{ padding: 2, backgroundColor: 'var(--primary-color)', color: 'white' }}>
    <Typography variant="h6">{title}</Typography>
  </Box>
);

// Компонент панели
const Panel = ({ title, description, onClick }) => (
  <Paper elevation={3} onClick={onClick} sx={{ margin: 1, cursor: 'pointer', backgroundColor: 'var(--background-color)', color: 'var(--text-color)', height: '100%' }}>
    <PanelHeading title={title} />
    <Box sx={{ padding: 2 }}>
      <Typography>{description}</Typography>
    </Box>
  </Paper>
);

// Мемоизированный селектор для получения данных из Redux
const selectCourses = createSelector(
  (state) => state.texts.courses,
  (state) => state.texts.loading,
  (state) => state.texts.error,
  (state) => state.auth.user,
  (state) => state.theme.theme,
  (courses, loading, error, user, theme) => ({ courses, loading, error, user, theme })
);

// Основной компонент HomePage
const HomePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { courses, loading, error, user, theme } = useSelector(selectCourses);

  // Загрузка курсов при монтировании компонента
  useEffect(() => {
    dispatch(fetchCourses());
  }, [dispatch]);

  // Обработка загрузки и ошибок
  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography>Error: {error}</Typography>;

  // Обработчик клика по курсу
  const handleCourseClick = (courseId, courseName) => {
    const courseData = {
      course_id: courseId,
      course_name: courseName,
    };
    dispatch(setCourseId(courseId)); // Сохраняем выбранный курс в Redux
    dispatch(setSelectedCourseName(courseName)); // Сохраняем название курса
    navigate(`/text-analyzer/${courseId}`, { state: courseData });
  };

  // Настройки для карусели
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  // Проверка загрузки данных
  if (!courses || !user) return <Typography>Loading...</Typography>;

  return (
    <Box sx={{ padding: 0, backgroundColor: 'var(--background-color)', color: 'var(--text-color)' }}>

      {/* Контейнер для каруселей */}
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '70vh' }}>
        {/* Divider для публичных курсов */}
        <Divider textAlign="left" sx={{ my: 0 }}>
          <Typography variant="p">Public courses</Typography>
        </Divider>

        {/* Карусель для публичных курсов */}
        <Box sx={{ flex: 1, overflowX: 'auto', marginBottom: '1rem' }}>
          <Carousel responsive={responsive} arrows={true} showDots={true} className="carousel-container">
            {courses
              .filter((course) => course.is_public) // Фильтрация публичных курсов
              .map((course) => (
                <Panel
                  key={course.id}
                  title={course.name}
                  description={course.description}
                  onClick={() => handleCourseClick(course.id, course.name)}
                />
              ))}
          </Carousel>
        </Box>

        {/* Divider для курсов пользователя */}
        <Divider textAlign="left" sx={{ my: 0 }}>
          <Typography variant="p">Your courses</Typography>
        </Divider>

        {/* Карусель для курсов пользователя */}
        <Box sx={{ flex: 1, overflowX: 'auto' }}>
          <Carousel responsive={responsive} arrows={true} showDots={true} className="carousel-container">
            {courses
              .filter((course) => course.created_by === user.id) // Фильтрация курсов пользователя
              .map((course) => (
                <Panel
                  key={course.id}
                  title={course.name}
                  description={course.description}
                  onClick={() => handleCourseClick(course.id, course.name)}
                />
              ))}
          </Carousel>
        </Box>
      </Box>
    </Box>
  );
};

export default HomePage;