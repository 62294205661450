import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  analyzeText,
  setArticleText,
  fetchThemes,
  fetchArticles,
  setArticleTextFromWeb,
  setCourseId,
  checkSubscriptionStatus,
} from "../redux/textsSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import api from "../axios";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Modal,
  Button,
  TextField,
  Select,
  MenuItem,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Typography,
  Box,
  Paper,
  FormControl,
  InputLabel,
  InputAdornment,
  Chip,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
  Divider, // Импортируем Divider
} from "@mui/material";
import {
  Description,
  PictureAsPdf,
  Image,
  Mic,
  YouTube,
  ArrowBack,
  Link as LinkIcon,
  Translate,
  AudioFile,
  ExpandMore,
  Delete,
  Close,
  CloudUpload,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const ItemTypes = {
  THEME: "theme",
  ARTICLE: "article",
};

const Theme = ({ theme, articles, handleShowText, handleDeleteArticle, isExpanded, toggleExpand }) => {
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.THEME,
    item: { id: theme.id, type: ItemTypes.THEME },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }}>
      <Paper
        sx={{
          mb: 2,
          p: 2,
          backgroundColor: 'var(--background-color)',
          color: 'var(--text-color)',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", cursor: "pointer" }} onClick={toggleExpand}>
          <Typography variant="p" sx={{ mr: 1 }}>
            {theme.name}
          </Typography>
          <Chip
            label={articles.length}
            size="small"
            color="primary"
            sx={{ backgroundColor: 'var(--primary-color)', color: '#fff' }}
          />
          <IconButton size="small" sx={{ ml: 1 }}>
            <ExpandMore />
          </IconButton>
        </Box>
        {isExpanded && (
          <List>
            {articles.map((article, index) => (
              <React.Fragment key={article.id}>
                <ListItem
                  sx={{ pl: 4, cursor: "pointer" }}
                  onClick={() => handleShowText(article.id, article.name, article.text, article.theme, article.text_language, article.translation_language)}
                >
                  <Description sx={{ mr: 1 }} />
                  <ListItemText primary={article.name} />
                  <IconButton
                    edge="end"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteArticle(article.id);
                    }}
                  >
                    <Delete color="error" />
                  </IconButton>
                </ListItem>
                {index < articles.length - 1 && <Divider />} {/* Добавляем Divider между элементами списка */}
              </React.Fragment>
            ))}
          </List>
        )}
      </Paper>
    </div>
  );
};

const Article = ({ article, handleShowText, handleDeleteArticle }) => {
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.ARTICLE,
    item: { id: article.id, type: ItemTypes.ARTICLE },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <ListItem
      ref={drag}
      style={{ opacity: isDragging ? 0.5 : 1, cursor: "pointer" }}
      onClick={() => handleShowText(article.id, article.name, article.text, article.theme, article.text_language, article.translation_language)}
    >
      <ListItemText primary={article.name} />
      <IconButton
        edge="end"
        onClick={(e) => {
          e.stopPropagation();
          handleDeleteArticle(article.id);
        }}
      >
        <Delete color="error" />
      </IconButton>
    </ListItem>
  );
};

const TextAnalyzer = () => {
  const [text, setText] = useState("");
  const [title, setTitle] = useState("");
  const [theme, setTheme] = useState("");
  const [loading, setLoading] = useState(false);
  const [articleUrl, setArticleUrl] = useState("");
  const [error, setError] = useState("");
  const [showLoadArticleModal, setShowLoadArticleModal] = useState(false);
  const [videoFile, setVideoFile] = useState(null);
  const [showLoadYoutubeModal, setShowLoadYoutubeModal] = useState(false);
  const [youtubeLoading, setYoutubeLoading] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);
  const [showLoadPdfModal, setShowLoadPdfModal] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [showLoadImageModal, setShowLoadImageModal] = useState(false);
  const [ocrLoading, setOcrLoading] = useState(false);
  const [ocrLanguage, setOcrLanguage] = useState("de");
  const [articleLanguage, setArticleLanguage] = useState("de-DE");
  const [translationLanguage, setTranslationLanguage] = useState("ru-RU");
  const [expandedThemes, setExpandedThemes] = useState({});

  const [showLoadAudioModal, setShowLoadAudioModal] = useState(false);
  const [audioFile, setAudioFile] = useState(null);
  const [audioLanguage, setAudioLanguage] = useState("de");
  const [audioLoading, setAudioLoading] = useState(false);

  const [youtubeLanguage, setYoutubeLanguage] = useState("de");

  const [openSpeedDial, setOpenSpeedDial] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { themes, articles } = useSelector((state) => state.texts);
  const { courseId } = useParams();
  const user = useSelector((state) => state.auth.user);
  const subscriptionStatus = useSelector((state) => state.texts.subscriptionStatus);
  const selectedCourseName = useSelector((state) => state.texts.selectedCourseName);

  const location = useLocation();
  const courseData = location.state;
  const courseName = courseData?.course_name;

  const [sentences, setSentences] = useState([]);

  useEffect(() => {
    if (courseId) {
      dispatch(fetchThemes(courseId));
      dispatch(fetchArticles({ courseId, themeId: null }));
    } else {
      dispatch(fetchThemes());
      dispatch(fetchArticles({ courseId: null, themeId: null }));
    }

    dispatch(checkSubscriptionStatus());
  }, [dispatch, courseId]);

  const handleShowText = (artId, artTitle, artText, artTheme, artTextLang, artTransLang) => {
    const articleData = {
      id: artId,
      title: artTitle,
      text: artText,
      theme: artTheme,
      text_language: artTextLang,
      translation_language: artTransLang,
    };

    navigate(`/articles/${artId}`, { state: articleData });
  };

  const handleLoadArticle = async () => {
    if (!user || (user.role !== "admin" && user.role !== "editor")) {
      toast.error("You do not have permission to load articles.");
      return;
    }

    if (subscriptionStatus !== "active") {
      toast.error("You need an active subscription to load articles.");
      return;
    }

    setLoading(true);
    setError("");

    try {
      const encodedUrl = btoa(articleUrl);
      const response = await api.post("/parse-article/", {
        url: encodedUrl,
      });
      const articleText = response.data.text;
      dispatch(setArticleTextFromWeb(articleText));
      navigate("/add-text");
    } catch (err) {
      setError("Failed to load article. Please check the URL and try again.");
      toast.error("Failed to load article. Please check the URL and try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleLoadVideoTranscript = async () => {
    if (!user || (user.role !== "admin" && user.role !== "editor")) {
      toast.error("You do not have permission to load video transcripts.");
      return;
    }

    if (subscriptionStatus !== "active") {
      toast.error("You need an active subscription to load video transcripts.");
      return;
    }

    if (!videoFile) {
      toast.error("No video file selected.");
      return;
    }

    setYoutubeLoading(true);
    setError("");

    const formData = new FormData();
    formData.append("file", videoFile);
    formData.append("language", youtubeLanguage);

    try {
      const response = await api.post("/get-youtube-transcript/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.text) {
        const transcriptText = response.data.text;
        dispatch(setArticleTextFromWeb(transcriptText));
        navigate("/add-text");
        toast.success("Video transcript uploaded successfully!");
      } else {
        setError("Failed to retrieve transcript. Please try again.");
        toast.error("Failed to retrieve transcript. Please try again.");
      }
    } catch (err) {
      const errorMessage = err.response?.data?.error || "Failed to load video transcript. Please try again.";
      setError(errorMessage);
      toast.error(errorMessage);
    } finally {
      setYoutubeLoading(false);
    }
  };

  const handleFileUpload = async (e) => {
    if (!user || (user.role !== "admin" && user.role !== "editor")) {
      toast.error("You do not have permission to upload PDFs.");
      return;
    }

    if (subscriptionStatus !== "active") {
      toast.error("You need an active subscription to upload PDFs.");
      return;
    }

    const file = e.target.files[0];
    if (file) {
      setPdfFile(file);
      setPdfLoading(true);
      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await api.post("/upload-pdf/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        const pdfText = response.data.text;
        dispatch(setArticleTextFromWeb(pdfText));
        navigate("/add-text");
        toast.success("PDF uploaded successfully!");
      } catch (err) {
        setError("Failed to load PDF. Please try again.");
        toast.error("Failed to load PDF. Please try again.");
      } finally {
        setPdfLoading(false);
      }
    }
  };

  const handleDeleteArticle = async (articleId) => {
    if (!user || (user.role !== "admin" && user.role !== "editor")) {
      toast.error("You do not have permission to delete articles.");
      return;
    }

    try {
      await api.delete(`/articles/${articleId}/`);
      dispatch(fetchArticles({ courseId, themeId: theme }));
      toast.success("Article deleted successfully!");
    } catch (error) {
      console.error("Error deleting article:", error);
      toast.error("Failed to delete article.");
    }
  };

  const handleImageUpload = async (e) => {
    if (!user || (user.role !== "admin" && user.role !== "editor")) {
      toast.error("You do not have permission to upload images.");
      return;
    }

    if (subscriptionStatus !== "active") {
      toast.error("You need an active subscription to upload images.");
      return;
    }

    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      setOcrLoading(true);
      const formData = new FormData();
      formData.append("image", file);
      formData.append("language", ocrLanguage);

      try {
        const response = await api.post("/upload-image/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        const imageText = response.data.text;
        dispatch(setArticleTextFromWeb(imageText));
        navigate("/add-text");
        toast.success("Image uploaded successfully!");
      } catch (err) {
        setError("Failed to extract text from image. Please try again.");
        toast.error("Failed to extract text from image. Please try again.");
      } finally {
        setOcrLoading(false);
      }
    }
  };

  const handleAudioUpload = async (file, selectedLanguage) => {
    if (!user || (user.role !== "admin" && user.role !== "editor")) {
      toast.error("You do not have permission to upload audio files.");
      return;
    }

    if (subscriptionStatus !== "active") {
      toast.error("You need an active subscription to upload audio files.");
      return;
    }

    if (!file) {
      toast.error("No audio file selected.");
      return;
    }

    setAudioLoading(true);
    const formData = new FormData();
    formData.append("audio", file);
    formData.append("language", selectedLanguage);

    try {
      const response = await api.post("/upload-audio/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.text) {
        const audioText = response.data.text;
        dispatch(setArticleTextFromWeb(audioText));
        navigate("/add-text");
        toast.success("Audio uploaded successfully!");
      } else {
        setError("Failed to retrieve transcription. Please try again.");
        toast.error("Failed to retrieve transcription. Please try again.");
      }
    } catch (err) {
      const errorMessage = err.response?.data?.error || "Failed to transcribe audio. Please try again.";
      setError(errorMessage);
      toast.error(errorMessage);
    } finally {
      setAudioLoading(false);
    }
  };

  const toggleExpand = (themeId) => {
    setExpandedThemes((prev) => ({
      ...prev,
      [themeId]: !prev[themeId],
    }));
  };

  const actions = [
    { icon: <Description sx={{ color: '#4caf50' }} />, name: 'Add Text', onClick: () => navigate("/add-text") },
    { icon: <LinkIcon sx={{ color: '#00bcd4' }} />, name: 'Load Article', onClick: () => setShowLoadArticleModal(true) },
    { icon: <PictureAsPdf sx={{ color: '#ff9800' }} />, name: 'Load PDF', onClick: () => setShowLoadPdfModal(true) },
    { icon: <Image sx={{ color: '#2196f3' }} />, name: 'Load Image', onClick: () => setShowLoadImageModal(true) },
    { icon: <Mic sx={{ color: '#9c27b0' }} />, name: 'Load Audio', onClick: () => setShowLoadAudioModal(true) },
    { icon: <YouTube sx={{ color: '#f44336' }} />, name: 'Load Video', onClick: () => setShowLoadYoutubeModal(true) },
  ];

  return (
    <Box
      sx={{
        padding: 0,
        backgroundColor: 'var(--background-color)',
        color: 'var(--text-color)',
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBack />
        </IconButton>
        <Typography variant="h5" sx={{ ml: 2 }}>
          {selectedCourseName}
        </Typography>
      </Box>

      <SpeedDial
        ariaLabel="SpeedDial for adding articles"
        sx={{
          position: 'fixed',
          bottom: 80,
          right: 16,
          '& .MuiFab-primary': {
            backgroundColor: '#4caf50',
            '&:hover': {
              backgroundColor: '#388e3c',
            },
          },
        }}
        icon={<SpeedDialIcon />}
        onClose={() => setOpenSpeedDial(false)}
        onOpen={() => setOpenSpeedDial(true)}
        open={openSpeedDial}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={action.onClick}
          />
        ))}
      </SpeedDial>

      <Modal
        open={showLoadArticleModal}
        onClose={() => setShowLoadArticleModal(false)}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            backgroundColor: 'var(--background-color)',
            color: 'var(--text-color)',
            p: 3,
            borderRadius: 2,
            boxShadow: 24,
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6" gutterBottom>
              Load From URL
            </Typography>
            <IconButton onClick={() => setShowLoadArticleModal(false)}>
              <Close />
            </IconButton>
          </Box>
          <TextField
            fullWidth
            label="Enter Article URL"
            value={articleUrl}
            onChange={(e) => setArticleUrl(e.target.value)}
            margin="normal"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LinkIcon />
                </InputAdornment>
              ),
            }}
            sx={{ mb: 2 }}
          />
          {error && <Typography color="error">{error}</Typography>}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button
              onClick={handleLoadArticle}
              disabled={loading}
              variant="contained"
              sx={{ mr: 2 }}
            >
              {loading ? <CircularProgress size={24} /> : "Load Article"}
            </Button>
            <Button
              onClick={() => setShowLoadArticleModal(false)}
              variant="outlined"
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={showLoadYoutubeModal}
        onClose={() => setShowLoadYoutubeModal(false)}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            backgroundColor: 'var(--background-color)',
            color: 'var(--text-color)',
            p: 3,
            borderRadius: 2,
            boxShadow: 24,
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6" gutterBottom>
              Load Video File
            </Typography>
            <IconButton onClick={() => setShowLoadYoutubeModal(false)}>
              <Close />
            </IconButton>
          </Box>
          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUpload />}
            sx={{ mb: 2 }}
          >
            Upload Video
            <VisuallyHiddenInput
              type="file"
              accept="video/*"
              onChange={(e) => setVideoFile(e.target.files[0])}
            />
          </Button>
          {videoFile && (
            <Typography variant="body1" sx={{ mb: 2 }}>
              Selected file: {videoFile.name}
            </Typography>
          )}
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Select Transcription Language</InputLabel>
            <Select
              value={youtubeLanguage}
              onChange={(e) => setYoutubeLanguage(e.target.value)}
              label="Select Transcription Language"
              margin="normal"
              startAdornment={
                <InputAdornment position="start">
                  <Translate />
                </InputAdornment>
              }
            >
              <MenuItem value="de">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Translate sx={{ mr: 1 }} /> German
                </Box>
              </MenuItem>
              <MenuItem value="en">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Translate sx={{ mr: 1 }} /> English
                </Box>
              </MenuItem>
              <MenuItem value="ru">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Translate sx={{ mr: 1 }} /> Russian
                </Box>
              </MenuItem>
            </Select>
          </FormControl>
          {error && <Typography color="error">{error}</Typography>}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button
              onClick={handleLoadVideoTranscript}
              disabled={youtubeLoading}
              variant="contained"
              sx={{ mr: 2 }}
            >
              {youtubeLoading ? (
                <CircularProgress size={24} />
              ) : (
                "Load Transcript"
              )}
            </Button>
            <Button
              onClick={() => setShowLoadYoutubeModal(false)}
              variant="outlined"
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={showLoadPdfModal} onClose={() => setShowLoadPdfModal(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            backgroundColor: 'var(--background-color)',
            color: 'var(--text-color)',
            p: 3,
            borderRadius: 2,
            boxShadow: 24,
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6" gutterBottom>
              Load PDF
            </Typography>
            <IconButton onClick={() => setShowLoadPdfModal(false)}>
              <Close />
            </IconButton>
          </Box>
          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUpload />}
            sx={{ mb: 2 }}
          >
            Upload PDF
            <VisuallyHiddenInput
              type="file"
              accept="application/pdf"
              onChange={handleFileUpload}
            />
          </Button>
          {pdfFile && (
            <Typography variant="body1" sx={{ mb: 2 }}>
              Selected file: {pdfFile.name}
            </Typography>
          )}
          {pdfLoading && <CircularProgress sx={{ mb: 2 }} />}
          {error && <Typography color="error">{error}</Typography>}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button
              onClick={() => setShowLoadPdfModal(false)}
              variant="outlined"
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={showLoadImageModal}
        onClose={() => setShowLoadImageModal(false)}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            backgroundColor: 'var(--background-color)',
            color: 'var(--text-color)',
            p: 3,
            borderRadius: 2,
            boxShadow: 24,
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6" gutterBottom>
              Load Image
            </Typography>
            <IconButton onClick={() => setShowLoadImageModal(false)}>
              <Close />
            </IconButton>
          </Box>
          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUpload />}
            sx={{ mb: 2 }}
          >
            Upload Image
            <VisuallyHiddenInput
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
            />
          </Button>
          {imageFile && (
            <Typography variant="body1" sx={{ mb: 2 }}>
              Selected file: {imageFile.name}
            </Typography>
          )}
          {ocrLoading && <CircularProgress sx={{ mb: 2 }} />}
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Select OCR Language</InputLabel>
            <Select
              value={ocrLanguage}
              onChange={(e) => setOcrLanguage(e.target.value)}
              label="Select OCR Language"
              margin="normal"
              startAdornment={
                <InputAdornment position="start">
                  <Translate />
                </InputAdornment>
              }
            >
              <MenuItem value="de">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Translate sx={{ mr: 1 }} /> German
                </Box>
              </MenuItem>
              <MenuItem value="en">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Translate sx={{ mr: 1 }} /> English
                </Box>
              </MenuItem>
              <MenuItem value="ru">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Translate sx={{ mr: 1 }} /> Russian
                </Box>
              </MenuItem>
            </Select>
          </FormControl>
          {error && <Typography color="error">{error}</Typography>}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button
              onClick={() => setShowLoadImageModal(false)}
              variant="outlined"
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={showLoadAudioModal}
        onClose={() => setShowLoadAudioModal(false)}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            backgroundColor: 'var(--background-color)',
            color: 'var(--text-color)',
            p: 3,
            borderRadius: 2,
            boxShadow: 24,
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6" gutterBottom>
              Load Audio
            </Typography>
            <IconButton onClick={() => setShowLoadAudioModal(false)}>
              <Close />
            </IconButton>
          </Box>
          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUpload />}
            sx={{ mb: 2 }}
          >
            Upload Audio
            <VisuallyHiddenInput
              type="file"
              accept="audio/*"
              onChange={(e) => setAudioFile(e.target.files[0])}
            />
          </Button>
          {audioFile && (
            <Typography variant="body1" sx={{ mb: 2 }}>
              Selected file: {audioFile.name}
            </Typography>
          )}
          {audioLoading && <CircularProgress sx={{ mb: 2 }} />}
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Select Audio Language</InputLabel>
            <Select
              value={audioLanguage}
              onChange={(e) => setAudioLanguage(e.target.value)}
              label="Select Audio Language"
              margin="normal"
              startAdornment={
                <InputAdornment position="start">
                  <Translate />
                </InputAdornment>
              }
            >
              <MenuItem value="en">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Translate sx={{ mr: 1 }} /> English
                </Box>
              </MenuItem>
              <MenuItem value="de">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Translate sx={{ mr: 1 }} /> German
                </Box>
              </MenuItem>
              <MenuItem value="ru">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Translate sx={{ mr: 1 }} /> Russian
                </Box>
              </MenuItem>
            </Select>
          </FormControl>
          {error && <Typography color="error">{error}</Typography>}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button
              onClick={() => handleAudioUpload(audioFile, audioLanguage)}
              disabled={audioLoading}
              variant="contained"
              sx={{ mr: 2 }}
            >
              {audioLoading ? <CircularProgress size={24} /> : "Transcribe Audio"}
            </Button>
            <Button
              onClick={() => setShowLoadAudioModal(false)}
              variant="outlined"
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>

      <DndProvider backend={HTML5Backend}>
        <Box>
          {themes.map((theme) => (
            <Theme
              key={theme.id}
              theme={theme}
              articles={articles.filter(
                (article) => article.theme === theme.id
              )}
              handleShowText={handleShowText}
              handleDeleteArticle={handleDeleteArticle}
              isExpanded={expandedThemes[theme.id]}
              toggleExpand={() => toggleExpand(theme.id)}
            />
          ))}
        </Box>
      </DndProvider>

      <ToastContainer />
    </Box>
  );
};

export default TextAnalyzer;