import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logoutUser } from '../redux/authSlice';
import { AppBar, Toolbar, Typography, Button, IconButton, Box } from '@mui/material';
import { Person, ExitToApp } from '@mui/icons-material';

const CustomNavbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Получаем текущую тему из state.theme.theme
  const theme = useSelector((state) => state.theme.theme);

  const handleLogout = async () => {
    try {
      await dispatch(logoutUser()).unwrap();

      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'exitApp' }));
      } else {
        navigate('/login');
      }
    } catch (err) {
      alert('Logout failed. Please try again.');
    }
  };

  const handleProfileClick = () => {
    navigate('/profile');
  };

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: theme === 'dark' ? '#121212' : '#ffffff',
        color: theme === 'dark' ? '#e0e0e0' : '#000000',
      }}
    >
      <Toolbar>
        {/* Логотип с кастомными стилями */}
        <Typography
          variant="h6"
          component="div"
          sx={{
            flexGrow: 1,
            color: theme === 'dark' ? '#e0e0e0' : '#000000', // Цвет текста по умолчанию
            '&::first-letter': {
              color: 'red', // Цвет первой буквы
            },
            '& span': {
              color: 'red', // Цвет всех заглавных букв
            },
          }}
        >
          <span>E</span>-Lingvo
        </Typography>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {/* Кнопка профиля */}
          <IconButton
            color="inherit"
            onClick={handleProfileClick}
            sx={{ marginRight: 2 }}
          >
            <Person />
          </IconButton>

          {/* Кнопка выхода */}
          <Button
            variant="outlined"
            color={theme === 'dark' ? 'secondary' : 'error'}
            startIcon={<ExitToApp />}
            onClick={handleLogout}
          >
            Exit
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default CustomNavbar;