// import React, { useState } from 'react';
// import { useDispatch } from 'react-redux';
// import { useParams, useNavigate } from 'react-router-dom';
// import { resetPassword } from '../redux/textsSlice';
// import { Form, Button, Alert } from 'react-bootstrap';

// const PasswordReset = () => {
//   const { token } = useParams();
//   const [password, setPassword] = useState('');
//   const [confirmPassword, setConfirmPassword] = useState('');
//   const [error, setError] = useState(null);
//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (password !== confirmPassword) {
//       setError('Passwords do not match');
//       return;
//     }
//     try {
//       await dispatch(resetPassword({ token, password })).unwrap();
//       alert('Password reset successfully!');
//       navigate('/login');
//     } catch (err) {
//       setError('Password reset failed. Please try again.');
//     }
//   };

//   return (
//     <div className="container mt-5">
//       <h2>Reset Password</h2>
//       {error && <Alert variant="danger">{error}</Alert>}
//       <Form onSubmit={handleSubmit}>
//         <Form.Group controlId="formPassword">
//           <Form.Label>New Password</Form.Label>
//           <Form.Control
//             type="password"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//             placeholder="New Password"
//           />
//         </Form.Group>
//         <Form.Group controlId="formConfirmPassword">
//           <Form.Label>Confirm New Password</Form.Label>
//           <Form.Control
//             type="password"
//             value={confirmPassword}
//             onChange={(e) => setConfirmPassword(e.target.value)}
//             placeholder="Confirm New Password"
//           />
//         </Form.Group>
//         <Button variant="primary" type="submit" className="mt-3">
//           Reset Password
//         </Button>
//       </Form>
//     </div>
//   );
// };

// export default PasswordReset;


import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { resetPassword } from '../redux/textsSlice';
import {
  TextField,
  Button,
  IconButton,
  Alert,
  Container,
  Typography,
  Box,
  CircularProgress,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';


const PasswordReset = () => {
  const { token } = useParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // Состояние загрузки
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null); // Сброс ошибки перед каждой попыткой

    // Проверка на совпадение паролей
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    setIsLoading(true); // Включаем загрузку

    try {
      await dispatch(resetPassword({ token, password })).unwrap();
      navigate('/login');
    } catch (err) {
      setError('Password reset failed. Please try again.');
    } finally {
      setIsLoading(false); // Выключаем загрузку
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 5 }}>
     <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBack />
        </IconButton>
        <Typography variant="h4" gutterBottom align="center">
        Reset Password
      </Typography>
      </Box>


      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="New Password"
          type="password"
          id="password"
          autoComplete="new-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="confirmPassword"
          label="Confirm New Password"
          type="password"
          id="confirmPassword"
          autoComplete="new-password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          sx={{ mb: 2 }}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          disabled={isLoading} // Блокируем кнопку при загрузке
          sx={{ mt: 3, mb: 2 }}
        >
          {isLoading ? <CircularProgress size={24} /> : 'Reset Password'} {/* Индикатор загрузки */}
        </Button>
      </Box>
    </Container>
  );
};

export default PasswordReset;