import React from "react";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import {
  ColorLens as ColorLensIcon,
  LibraryBooks as LibraryBooksIcon,
  MenuBook as MenuBookIcon,
  Home as HomeIcon,
  Article as ArticleIcon, // Иконка для TextAnalyzer
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const BottomNav = () => {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const courseId = useSelector((state) => state.texts.courseId); // Получаем courseId из Redux

  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 1000 }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        {/* Кнопка Home */}
        <BottomNavigationAction
          label="Home"
          icon={<HomeIcon />}
          onClick={() => navigate("/")}
        />

        {/* Кнопка Text Analyzer */}
        <BottomNavigationAction
          label="Analyzer"
          icon={<ArticleIcon />}
          onClick={() => navigate(`/text-analyzer/${courseId}`)}
          disabled={!courseId} // Делаем кнопку неактивной, если курс не выбран
        />

        {/* Кнопка Theme Manager */}
        <BottomNavigationAction
          label="Themes"
          icon={<ColorLensIcon />}
          onClick={() => navigate("/theme-manager")}
          disabled={!courseId} // Делаем кнопку неактивной, если курс не выбран
        />

        {/* Кнопка User Expressions */}
        <BottomNavigationAction
          label="Expressions"
          icon={<LibraryBooksIcon />}
          onClick={() => navigate("/user-expressions")}
          disabled={!courseId} // Делаем кнопку неактивной, если курс не выбран
        />

        {/* Кнопка Full Dictionary */}
        <BottomNavigationAction
          label="Dictionary"
          icon={<MenuBookIcon />}
          onClick={() => navigate("/full-dictionary")}
        />
      </BottomNavigation>
    </Paper>
  );
};

export default BottomNav;