// import React, { useState } from 'react';
// import { useDispatch } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import { registerUser } from '../redux/authSlice';
// import { Form, Button, Alert } from 'react-bootstrap';

// const Register = () => {
//   const [username, setUsername] = useState('');
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [confirmPassword, setConfirmPassword] = useState('');
//   const [textLanguage, setTextLanguage] = useState('de');
//   const [translationLanguage, setTranslationLanguage] = useState('ru');
//   const [error, setError] = useState(null);
//   const [successMessage, setSuccessMessage] = useState(null); // Добавляем состояние для сообщения об успехе
//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     // Проверка на заполненность всех полей
//     if (!username || !email || !password || !confirmPassword || !textLanguage || !translationLanguage) {
//       setError('Please fill in all fields');
//       return;
//     }

//     // Проверка на совпадение паролей
//     if (password !== confirmPassword) {
//       setError('Passwords do not match');
//       return;
//     }

//     try {
//       await dispatch(registerUser({ username, email, password, textLanguage, translationLanguage })).unwrap();
//       setSuccessMessage('Registration successful! A confirmation email has been sent to your email address.'); // Устанавливаем сообщение об успехе
//       navigate('/login');
//     } catch (err) {
//       setError('Registration failed. Please try again.');
//     }
//   };

//   return (
//     <div className="container mt-5">
//       <h2>Register</h2>
//       {error && <Alert variant="danger">{error}</Alert>}
//       {successMessage && <Alert variant="success">{successMessage}</Alert>} {/* Отображаем сообщение об успехе */}
//       <Form onSubmit={handleSubmit}>
//         <Form.Group controlId="formUsername">
//           <Form.Label>Username</Form.Label>
//           <Form.Control
//             type="text"
//             value={username}
//             onChange={(e) => setUsername(e.target.value)}
//             placeholder="Username"
//           />
//         </Form.Group>

//         <Form.Group controlId="formEmail">
//           <Form.Label>Email</Form.Label>
//           <Form.Control
//             type="email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             placeholder="Enter email"
//           />
//         </Form.Group>

//         <Form.Group controlId="formPassword">
//           <Form.Label>Password</Form.Label>
//           <Form.Control
//             type="password"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//             placeholder="Password"
//           />
//         </Form.Group>

//         <Form.Group controlId="formConfirmPassword">
//           <Form.Label>Confirm Password</Form.Label>
//           <Form.Control
//             type="password"
//             value={confirmPassword}
//             onChange={(e) => setConfirmPassword(e.target.value)}
//             placeholder="Confirm Password"
//           />
//         </Form.Group>

//         <Form.Group controlId="formTextLanguage">
//           <Form.Label>Text Language</Form.Label>
//           <Form.Control
//             as="select"
//             value={textLanguage}
//             onChange={(e) => setTextLanguage(e.target.value)}
//           >
//             <option value="de">German</option>
//             <option value="en">English</option>
//             <option value="ru">Russian</option>
//             <option value="fr">French</option>
//             <option value="es">Spanish</option>
//             <option value="ar">Arabic</option>
//           </Form.Control>
//         </Form.Group>

//         <Form.Group controlId="formTranslationLanguage">
//           <Form.Label>Translation Language</Form.Label>
//           <Form.Control
//             as="select"
//             value={translationLanguage}
//             onChange={(e) => setTranslationLanguage(e.target.value)}
//           >
//             <option value="de">German</option>
//             <option value="en">English</option>
//             <option value="ru">Russian</option>
//             <option value="fr">French</option>
//             <option value="es">Spanish</option>
//             <option value="ar">Arabic</option>
//           </Form.Control>
//         </Form.Group>

//         <div className="mt-3">
//           <small>After registration, a confirmation email will be sent to your email address.</small>
//         </div>

//         <Button variant="primary" type="submit" className="mt-3">
//           Register
//         </Button>
//       </Form>
//     </div>
//   );
// };

// export default Register;


import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { registerUser } from '../redux/authSlice';
import {
  TextField,
  Button,
  IconButton,
  Alert,
  Container,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Box,
  CircularProgress,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';


const Register = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [textLanguage, setTextLanguage] = useState('de');
  const [translationLanguage, setTranslationLanguage] = useState('ru');
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // Состояние загрузки
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Валидация email
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  // Валидация пароля
  const validatePassword = (password) => {
    return password.length >= 6; // Пароль должен быть не менее 6 символов
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null); // Сброс ошибки перед каждой попыткой

    // Проверка на заполненность всех полей
    if (!username || !email || !password || !confirmPassword || !textLanguage || !translationLanguage) {
      setError('Please fill in all fields');
      return;
    }

    // Проверка валидности email
    if (!validateEmail(email)) {
      setError('Please enter a valid email address');
      return;
    }

    // Проверка валидности пароля
    if (!validatePassword(password)) {
      setError('Password must be at least 6 characters long');
      return;
    }

    // Проверка на совпадение паролей
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    setIsLoading(true); // Включаем загрузку

    try {
      await dispatch(
        registerUser({ 
          username, 
          email, 
          password, 
          text_language: textLanguage, // Исправлено на snake_case
          translation_language: translationLanguage, // Исправлено на snake_case
        
        })
      ).unwrap();
      setSuccessMessage('Registration successful! A confirmation email has been sent to your email address.');
      navigate('/login');
    } catch (err) {
      setError('Registration failed. Please try again.');
    } finally {
      setIsLoading(false); // Выключаем загрузку
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 5 }}>

     <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBack />
        </IconButton>
        <Typography variant="h5" gutterBottom align="center">
        Register
      </Typography>
      </Box>
      
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      {successMessage && <Alert severity="success" sx={{ mb: 2 }}>{successMessage}</Alert>}
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="username"
          label="Username"
          name="username"
          autoComplete="username"
          autoFocus
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="new-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="confirmPassword"
          label="Confirm Password"
          type="password"
          id="confirmPassword"
          autoComplete="new-password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          sx={{ mb: 2 }}
        />
        <FormControl fullWidth margin="normal" sx={{ mb: 2 }}>
          <InputLabel id="textLanguage-label">Text Language</InputLabel>
          <Select
            labelId="textLanguage-label"
            id="textLanguage"
            value={textLanguage}
            label="Text Language"
            onChange={(e) => setTextLanguage(e.target.value)}
          >
            <MenuItem value="de">German</MenuItem>
            <MenuItem value="en">English</MenuItem>
            <MenuItem value="ru">Russian</MenuItem>
            <MenuItem value="fr">French</MenuItem>
            <MenuItem value="es">Spanish</MenuItem>
            <MenuItem value="ar">Arabic</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal" sx={{ mb: 2 }}>
          <InputLabel id="translationLanguage-label">Translation Language</InputLabel>
          <Select
            labelId="translationLanguage-label"
            id="translationLanguage"
            value={translationLanguage}
            label="Translation Language"
            onChange={(e) => setTranslationLanguage(e.target.value)}
          >
            <MenuItem value="de">German</MenuItem>
            <MenuItem value="en">English</MenuItem>
            <MenuItem value="ru">Russian</MenuItem>
            <MenuItem value="fr">French</MenuItem>
            <MenuItem value="es">Spanish</MenuItem>
            <MenuItem value="ar">Arabic</MenuItem>
          </Select>
        </FormControl>
        <Typography variant="body2" sx={{ mt: 2, mb: 2 }}>
          After registration, a confirmation email will be sent to your email address.
        </Typography>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          disabled={isLoading} // Блокируем кнопку при загрузке
          sx={{ mt: 3, mb: 2 }}
        >
          {isLoading ? <CircularProgress size={24} /> : 'Register'} {/* Индикатор загрузки */}
        </Button>
      </Box>
    </Container>
  );
};

export default Register;